// Route / View options for App
export enum RouteOptions {
  landing = "/",
  submit = "/submit",
  collection = "/collection",
  discover = "/discover",
  analyze = "/analyze",
  QA = "/qa",
  content = "/content",
}

// Data Types
export enum AmpDataSubmitTypes {
  Evidence = "evidence",
  Content = "content",
  FlagForReview = "flagForReview",
}

// AmpCard Types
export enum AmpCardTypes {
  evidence = "evidence",
  theme = "theme",
  content = "content",
}

// Evidence Translation states
export enum TranslationState {
  native = "native",
  translated = "translated",
  untranslated = "untranslated",
}

// Evidence Status states
export enum EvidenceStatus {
  NeedsReview = "needsReview",
  Active = "active",
  Reviewed = "reviewed",
  // GoldStandard = "goldStandard",
  Archived = "archived",
}

// AmpDrawer states
export enum AmpDrawerState {
  Closed = "closed",
  Filters = "filters",
  Flag = "flag",
  Responses = "responses",
  EvidenceDetails = "evidenceDetails",
}

// AMP Badges
export enum AmpBadges {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
}

// Data Intensities
export enum DataIntensity {
  "High" = "High",
  "Low" = "Low",
}

// Sub-view options
export enum contentSubviews {
  AddContent = "AddContent",
  ViewContent = "ViewContent",
}

// Social Media Post Types
export enum SocialMediaPostType {
  Post = "Post",
  Video = "Video",
  Comment = "Comment",
  Tweet = "Tweet",
  Reply = "Reply",
}

// Presigned URL Upload types
export enum PresignedUrlTypes {
  Evidence = "Evidence",
  Content = "Content",
}

// SortField options
export enum SortableFields {
  Date = "date",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
}
