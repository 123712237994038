// Global
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

// MUI and Formik
import { Stack, Box, Typography } from "@mui/material";
import { Formik, Form } from "formik";

// Local
import TextFieldInput from "../../components/addEvidence/TextFieldInput";
import AmpButton from "../buttons/AmpButton";
import { AmpDrawerState } from "../../lib/util/enums";
import { CardTypeAndId } from "../../lib/util/interfaces";
import useFlagForReview from "../../hooks/useFlagForReview";
import CircleLoading from "../navigation/CircleLoading";
import CancelSubmitButtonPair from "../buttons/CancelSubmitButtonPair";

interface Props extends CardTypeAndId {
  setDrawerState: (text: AmpDrawerState) => void;
}

// This component populates the FlagForReview drawer
const FlagForReviewDrawer = ({ type, id, setDrawerState }: Props) => {
  // Global hooks
  const { t, i18n } = useTranslation();
  const { user } = useAuth0();

  // Local hooks and state
  const userLang = i18n.language;
  const { flagForReviewData, setDescription, submitFlagForReview, status } =
    useFlagForReview({
      type,
      id,
      user,
      userLang,
    });

  // Return visual based on mutation state
  return status === "loading" ? (
    <CircleLoading />
  ) : status === "error" || status === "success" ? (
    // Message for user post-submit success
    <Box m={2}>
      <Stack direction="column" spacing={2}>
        {status === "success" && (
          <>
            <Typography variant="h2">
              {t("flagForReview.Submitted1")}
            </Typography>
            <Typography variant="body1">
              {t("flagForReview.Submitted2")}
              {flagForReviewData.case}
            </Typography>
          </>
        )}
        {/* Message for user if error */}
        {status === "error" && (
          <Typography variant="h2">{t("flagForReview.Error")}</Typography>
        )}
        <Box>
          <AmpButton
            text="Confirm"
            onClick={() => setDrawerState(AmpDrawerState.Closed)}
          />
        </Box>
      </Stack>
    </Box>
  ) : (
    // Pre-submit UI
    <Box m={2}>
      <Stack direction="column" spacing={2}>
        {/* Header */}
        <Typography variant="h2">{t("flagForReview.Title")}</Typography>

        {/* Prefilled form info */}
        <Box>
          <Box>
            <Typography variant="h4" display={"inline"}>
              {t("flagForReview.Username")}
            </Typography>
            <Typography variant="body1" ml={0.5} display={"inline"}>
              {flagForReviewData.user}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4" display={"inline"}>
              {t("flagForReview.DataType")}
            </Typography>
            <Typography variant="body1" ml={0.5} display={"inline"}>
              {flagForReviewData.dataType === "evidence" ? "rumor" : type}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4" display={"inline"}>
              {t("flagForReview.DataId")}
            </Typography>
            <Typography variant="body1" ml={0.5} display={"inline"}>
              {flagForReviewData.opensearchId}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4" display={"inline"}>
              {t("flagForReview.Date")}
            </Typography>
            <Typography variant="body1" ml={0.5} display={"inline"}>
              {flagForReviewData.date}
            </Typography>
          </Box>
        </Box>

        {/* Free text form */}
        <Formik
          initialValues={{
            flagForReview: flagForReviewData.description || "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setDescription(values?.flagForReview);
            setSubmitting(false);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <TextFieldInput
                field="flagForReview"
                rows={4}
                rowsMobile={5}
                noTitle
              />
            </Form>
          )}
        </Formik>
        {/* submitFlagForReview, setDrawerState(AmpDrawerState.Closed) */}

        {/* Submit / Cancel buttons */}
        <CancelSubmitButtonPair
          submit={submitFlagForReview}
          cancel={() => setDrawerState(AmpDrawerState.Closed)}
        />
      </Stack>
    </Box>
  );
};

export default FlagForReviewDrawer;
