// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import { COLORS } from "../../lib/theme/default";

const LShapedSpacer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width={isSmallScreen ? "10vw" : "3vw"}>
      <Box
        sx={{
          width: "35%",
          height: "2px",
          backgroundColor: COLORS.secondaryGray,
          position: "relative",
          top: isSmallScreen ? "45%" : "50%",
          left: "50%",
          transform: "translateY(-50%)",
        }}
      />
      <Box
        sx={{
          width: "2px",
          height: isSmallScreen ? "42%" : "45%",
          backgroundColor: COLORS.secondaryGray,
          position: "relative",
          top: isSmallScreen ? "44%" : "49%",
          left: "50%",
          transform: "translate(-50%, -100%)",
        }}
      />
    </Box>
  );
};

export default LShapedSpacer;
