// Auth0 queryKey names
export const authToken = "authToken";
export const evidence = "evidence";
export const content = "content"
export const themesQuery = "themesQuery";
export const issuesQuery = "issuesQuery";
export const addContentQuery = "addContentQuery";
export const getContentQuery = "getContentQuery";
export const getAttachmentsQuery = "getAttachmentsQuery";
export const getAggregateThemesQuery = "getAggregateThemesQuery";
export const getEvidenceCountQuery = "getEvidenceCountQuery";
export const scrapeEvidenceQuery = "scrapeEvidenceQuery";
