// Globals
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stepper, Step, StepLabel } from "@mui/material";

interface Props {
  currentStep: number;
}

const steps = ["1", "2", "3", "4"];

const AddEvidenceGuideStepsMobile = ({currentStep}: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <Box sx={{ width: "100%", marginBottom: 1 }}>
      <Stepper activeStep={currentStep-1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t("guideStepsMobile." + label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
export default AddEvidenceGuideStepsMobile;
