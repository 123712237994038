import { useState } from "react";

// Store custom error messages in state to trigger user alerts
const useCustomError = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  return { errorMessage, setErrorMessage };
};

export default useCustomError;
