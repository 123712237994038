import { useTranslation } from "react-i18next";

// Import MUI
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Local Import
import { COLORS } from "../../lib/theme/default";

type guideProps = {
    n: number; // Number to visualize (-1)
    currentStep: number; // User's current step
  };

// Draw the input steps visualization
export default function AddEvidenceGuideSteps({ n, currentStep }: guideProps) {

    const { t, i18n } = useTranslation();

    // Build the translation key
    const guideStepText = 'guideSteps.' + n;

    // Set White=true if this is the currentStep visualization
    const white = currentStep >= n ? true : false;
    return (
      <div>
        {/* Draw the circle vector if current step is <= n*/}

        {currentStep <= n && (
          <svg
            width="40"
            height="40"
            viewBox="0 0 50 50"
            style={{ background: "transparent" }}
          >
            <circle
              cx="25"
              cy="25"
              r="20"
              stroke={white ? "#fff" : COLORS.secondaryGray}
              strokeWidth="3"
              fill="transparent"
            />
            {/* Add the number */}
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dy="0.3em"
              fill={white ? "#fff" : COLORS.secondaryGray}
              fontSize="20px"
            >
              {n}
            </text>
          </svg>
        )}

        {currentStep > n && (
          <Typography color={COLORS.airaPink}>
            <CheckCircleIcon sx={{ fontSize: 40 }} />
          </Typography>
        )}

        {/* Add the text and the vertical line */}
        <div>
          <Typography variant={white ? "h5" : "h4"}> {t(guideStepText)}</Typography>
        </div>
        {n !== 4 && (
          <div
            style={{
              marginLeft: "18px",
              marginTop: "10px",
              width: "2px",
              height: "24px",
              backgroundColor: white ? "#fff" : COLORS.secondaryGray,
            }}
          />
        )}
      </div>
    );
  };