// Global
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Outlet, useLocation } from "react-router-dom";

// MUI
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

// Local
import useAuthToken from "./hooks/useAuthToken";
import {
  ApiTokenContext,
  IssuesContext,
  ThemesContext,
} from "./contexts/InitContexts";
import CircleLoading from "./components/navigation/CircleLoading";
import Navbar from "./components/navigation/Navbar";
import { RouteOptions } from "./lib/util/enums";
import useThemesIssues from "./hooks/useThemesIssues";

// Auth0 function
export default withAuthenticationRequired(App, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (
    <div style={{ width: "100vw" }}>
      <CircleLoading />
    </div>
  ),
});

function App() {
  const location = useLocation();
  const apiToken = useAuthToken();

  // Get themes and issues, put into context
  const { themes, issues } = useThemesIssues(apiToken);

  return (
    <ApiTokenContext.Provider value={apiToken}>
      <ThemesContext.Provider value={themes}>
        <IssuesContext.Provider value={issues}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="App">
              {/* Navbar (hide on Submit route) */}
              {location.pathname !== RouteOptions.submit && <Navbar />}
              {/* Display currently applicable view */}
              <Outlet />
            </div>
          </LocalizationProvider>
        </IssuesContext.Provider>
      </ThemesContext.Provider>
    </ApiTokenContext.Provider>
  );
}
