// Global
import { useTranslation } from "react-i18next";

// Import formik and formik-mui
import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import { TextField } from "formik-mui";
import { Field } from "formik";
import { useMediaQuery, useTheme } from "@mui/material";

// Local
import { EvidenceFiltersProps } from "../../lib/util/interfaces";
import { COLORS } from "../../lib/theme/default";

const SearchBar = ({
  evidenceFilters,
  updateEvidenceFilters,
}: EvidenceFiltersProps) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Formik
        initialValues={{
          searchBar: evidenceFilters?.searchText || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateEvidenceFilters({ searchText: values.searchBar });
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mr={1} py={1}>
              <Field
                component={TextField}
                name={"searchBar"}
                type={"searchBar"}
                placeholder={t("subNav.SearchBarPlaceholder")}
                multiline={true}
                maxRows={3}
                size={"small"}
                sx={{
                  width: "100%",
                  backgroundColor: isSmallScreen
                    ? COLORS.white
                    : COLORS.backgroundGray,
                }}
                onBlur={(e: any) => {
                  // Update the form on blur
                  submitForm();
                }}
                onKeyPress={(event: any) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    submitForm();
                  }
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SearchBar;
