// Global
import { useTranslation } from "react-i18next";

// MUI and Formik
import { Box, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";

// Local
import AutocompleteMultiple from "../../addEvidence/AutocompleteMultiple";
import { EvidenceFiltersProps } from "../../../lib/util/interfaces";
import AmpCalendar from "../../filters/Calendar";
import AmpButton from "../../buttons/AmpButton";
import { hasAllUndefinedProperties } from "../../../lib/util/dataCleaning";
import SortableFieldsControl from "../../filters/SortableFieldsControl";
import ChipInput from "../../addEvidence/ChipInput";
import { EvidenceStatus } from "../../../lib/util/enums";

const FiltersDrawer = ({
  evidenceFilters,
  isQA,
  updateEvidenceFilters,
  clearEvidenceFilters,
}: EvidenceFiltersProps) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and const
  const isClear = hasAllUndefinedProperties(evidenceFilters);

  // In QA, force Status = [] not undefined
  const handleStatus = (
    values: Record<string, string> | Record<string, string[]>
  ) => {
    if (values?.status?.length === 0) {
      console.log("Must select a value!");
    } else {
      updateEvidenceFilters(values);
    }
  };

  // TODO: Add interactions, attachments

  return (
    <Box m={2} height={"100%"}>
      <Stack direction="column" spacing={2} height={"100%"}>
        <Typography variant="h2">{t("drawers.filters.Title")}</Typography>

        {isSmallScreen && (
          <>
            <AmpCalendar
              evidenceFilters={evidenceFilters}
              updateEvidenceFilters={updateEvidenceFilters}
            />
            <SortableFieldsControl
              evidenceFilters={evidenceFilters}
              updateEvidenceFilters={updateEvidenceFilters}
            />
          </>
        )}

        {/* Evidence Status options (QA only) */}
        {isQA && (
          <Formik
            initialValues={{
              status: undefined,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            <Form>
              {/* Chips to filter Evidence status */}
              <Box>
                <ChipInput
                  label="status"
                  values={Object.values(EvidenceStatus)}
                  selectedValues={evidenceFilters.status}
                  updateFormData={handleStatus}
                />
              </Box>
            </Form>
          </Formik>
        )}

        {/* Multi-select filters */}
        <AutocompleteMultiple
          field="countries"
          isFilter
          selectedValues={evidenceFilters?.countries}
          updateFormData={updateEvidenceFilters}
        />

        <AutocompleteMultiple
          field="issueIds"
          isFilter
          selectedValues={evidenceFilters?.issueIds}
          updateFormData={updateEvidenceFilters}
        />

        <AutocompleteMultiple
          field="themeIds"
          isFilter
          selectedValues={evidenceFilters?.themeIds}
          updateFormData={updateEvidenceFilters}
        />

        <AutocompleteMultiple
          field="languages"
          isFilter
          selectedValues={evidenceFilters?.languages}
          updateFormData={updateEvidenceFilters}
        />

        <AutocompleteMultiple
          field="platforms"
          isFilter
          selectedValues={evidenceFilters?.platforms}
          updateFormData={updateEvidenceFilters}
        />

        {!isClear && (
          <Box>
            <AmpButton
              type="text"
              text="Clear"
              onClick={clearEvidenceFilters}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
export default FiltersDrawer;
