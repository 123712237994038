// Globals
import { useContext, useState } from "react";
import { useQueries } from "@tanstack/react-query";

// Local
import getApiEnv from "../lib/util/getApiEnv";
import useAxios from "./useAxios";
import { ApiTokenContext } from "../contexts/InitContexts";
import getFileExtension from "../lib/util/getFileExtension";
import { AttachmentListProps } from "../lib/util/interfaces";
import { getAttachmentsQuery } from "../lib/api/keyConstants";
import { AttachmentTypes } from "../models/FileTypes";
import { isVideoAttachment } from "../lib/util/isVideoAttachment";

interface GetAttachmentsProps {
  attachment: string;
  apiToken: string;
}

// Business logic to get attachment
const getAttachments = async ({
  attachment,
  apiToken,
}: GetAttachmentsProps) => {
  const appUrl: string = import.meta.env.REACT_APP_REDIRECT_URI;
  const env: string = getApiEnv(appUrl);

  // Endpoint URL for getAttachments
  const url = "https://" + env + ".s3.amp.rootwise.co?key=" + attachment;

  return await useAxios({
    token: apiToken,
    external: true,
    endpoint: url,
    responseType: "blob",
  });
};

interface Props {
  attachments?: string[];
}

// State management for Attachments
export const useAttachments = ({ attachments }: Props) => {
  if (!attachments) {
    return { isLoading: false };
  }

  const [showVideos, setShowVideos] = useState<boolean>(false);
  const apiToken = useContext(ApiTokenContext);

  // Build an attachmentList for return
  const attachmentList: AttachmentListProps[] = [];

  // Parallel query to retrieve all attachments as a list
  const queryResults = useQueries({
    queries: attachments.map((attachment) => {
      return {
        queryKey: [getAttachmentsQuery, { attachment, apiToken }],
        queryFn: async () => getAttachments({ attachment, apiToken }),
        enabled: !isVideoAttachment(attachments) || showVideos,
      };
    }),
  });

  // Send loading query
  if (
    queryResults.some(
      (result: { isInitialLoading: boolean }) => result.isInitialLoading
    )
  ) {
    return { isLoading: true };
  }

  // Throw error on query error
  if (queryResults.some((result: { isError: boolean }) => result.isError)) {
    // throw new Error("getAttachments error");
    attachmentList.push({ attachmentType: AttachmentTypes.error });
  }

  // Loop through the results, add them to attachmentList
  for (const result of queryResults) {
    if (result?.data?.data) {
      const type = getFileExtension(result.data?.request.responseURL);
      attachmentList.push({
        attachmentType: type,
        blob: result.data.data,
      });
    }
  }

  return { attachmentList, showVideos, setShowVideos };
};

export default useAttachments;
