// import * as React from "react";
import { useTranslation } from "react-i18next";


// Import MUI
import { Box, Stack } from "@mui/material";
import AddEvidenceGuideSteps from "./AddEvidenceGuideSteps";

// Local Import
import { COLORS } from "../../lib/theme/default";


// This function displays the guide steps aiding the user
// in understanding the steps required to input evidence
export default function AddEvidenceGuide(props: { currentStep: number }) {
  const { t, i18n } = useTranslation();


  const guideSteps = [1, 2, 3, 4];

  return (
    <Box
      sx={{
        backgroundColor: COLORS.primaryBlue,
        height: 1,
        overflow: "hidden",
      }}
    >
      <Stack spacing={0} marginTop={2}>

    

        {guideSteps.map((guideStep) => (
          <Box textAlign="left" marginLeft={4} marginTop={2} key={guideStep}>
            <AddEvidenceGuideSteps n={guideStep} currentStep={props.currentStep} />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
