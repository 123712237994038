// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import SubNav from "../components/navigation/SubNav";
import { RouteOptions } from "../lib/util/enums";
import AmpCard, { AmpCardTypes } from "../components/cards/AmpCard";
import { appMaxWidth, appMobileWidth, cardMaxWidth } from "../lib/theme/default";
import CircleLoading from "../components/navigation/CircleLoading";
import useAggregateThemes from "../hooks/useAggregateThemes";
import { labeledObject } from "../lib/util/interfaces";
import ThemeCard from "../components/cards/ThemeCard";

const DiscoverRumors = () => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Component-specific hooks and variables
  const { themeList, isLoading } = useAggregateThemes({});

  return (
    <Box>
      {/* SubNav Container */}
      <SubNav view={RouteOptions.discover} />

      <Box display="flex" justifyContent={"center"}>
        <Box
          minWidth={isSmallScreen ? appMobileWidth : cardMaxWidth}
          maxWidth={isSmallScreen ? appMobileWidth : appMaxWidth}
          justifyContent={"center"}
          mt={2}
        >
          {isLoading && <CircleLoading />}

          {themeList &&
            themeList.map((theme: labeledObject) => (
              <AmpCard type={AmpCardTypes.theme} key={theme.id}>
                <ThemeCard data={theme} />
              </AmpCard>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DiscoverRumors;
