// MUI
import { Box } from "@mui/material";

// Local
import AmpButton from "./AmpButton";
import { FetchNextPageOptions } from "@tanstack/react-query";

interface Props {
  manageNextPage: {
    fetchNextPage: (options?: FetchNextPageOptions) => void;
    isFetchingNextPage: boolean;
    hasNextPage?: boolean;
  };
}
// LoadMore Button for Infinite Scroll
const LoadMoreButton = ({ manageNextPage }: Props) => {
  return (
    <Box display="flex" justifyContent={"center"} mb={2}>
      <AmpButton
        text="LoadMore"
        disabled={
          !manageNextPage?.hasNextPage || manageNextPage?.isFetchingNextPage
        }
        onClick={() => manageNextPage?.fetchNextPage()}
      />
    </Box>
  );
};

export default LoadMoreButton;
