// Global
import { useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// MUI
import {
  StaticDateRangePicker,
  DateRange,
  MobileDateRangePicker,
} from "@mui/x-date-pickers-pro";
import {
  Box,
  TextField,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Local
import {
  dateQuickOptions,
  formatDateRangeString,
} from "../../lib/util/dateFormatters";
import { EvidenceFiltersProps } from "../../lib/util/interfaces";
import { COLORS } from "../../lib/theme/default";

const AmpCalendar = ({
  evidenceFilters,
  updateEvidenceFilters,
}: EvidenceFiltersProps) => {
  // Global hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and state
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  // Handle opening and closing the calendar
  const handleOpenPicker = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // Handle date change
  const handleChange = (value: DateRange<dayjs.Dayjs>) => {
    // Update SearchParams if beginning and end date are defined
    if (!!value?.[0] && !!value?.[1]) {
      // Set gte to start of day and lte to end of day
      const gte = value[0].utc().startOf("day");
      const lte = value[1].utc().endOf("day");
      updateEvidenceFilters({ dateRange: { gte: gte, lte: lte } });
    }
  };

  // Calculate date display, add translation if applicable
  const dateString = formatDateRangeString(evidenceFilters.dateRange);
  const translatedDateString =
    dateString.slice(0, 4) === "Last" ? t("date." + dateString) : dateString;

  return (
    <Box mr={1}>
      {/* Visible text field */}
      {!isSmallScreen && (
        <TextField
          value={translatedDateString}
          onClick={handleOpenPicker}
          InputProps={{
            readOnly: true,
          }}
          size="small"
          sx={{ backgroundColor: COLORS.backgroundGray, width: "auto" }}
        />
      )}
      {/* Popover anchoring */}
      {!isSmallScreen && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box>
            <StaticDateRangePicker
              value={[
                evidenceFilters.dateRange.gte,
                evidenceFilters.dateRange.lte,
              ]}
              calendars={2}
              maxDate={dayjs().utc().endOf("day")}
              onAccept={handleClose}
              onChange={(value: DateRange<dayjs.Dayjs>) => handleChange(value)}
              slotProps={{
                shortcuts: {
                  items: dateQuickOptions,
                },
                actionBar: { actions: [],  },
                
              }}
            />
          </Box>
        </Popover>
      )}
      {isSmallScreen && (
        <MobileDateRangePicker
          value={[evidenceFilters.dateRange.gte, evidenceFilters.dateRange.lte]}
          calendars={1}
          maxDate={dayjs().utc().endOf("day")}
          onAccept={handleClose}
          onChange={(value: DateRange<dayjs.Dayjs>) => handleChange(value)}
        />
      )}
    </Box>
  );
};

export default AmpCalendar;
