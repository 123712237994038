// Global
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
dayjs.extend(utcPlugin);

// Local
import { idCountPair, labeledObject } from "../lib/util/interfaces";
import useAxios from "./useAxios";
import { getAggregateThemesQuery } from "../lib/api/keyConstants";
import { ApiTokenContext } from "../contexts/InitContexts";
import { ThemesContext } from "../contexts/InitContexts";
import { AxiosResponse } from "axios";
import constructAmpThemes from "../lib/util/constructAmpThemes";

interface QueryProps {
  queryKey: [string, { token: string; days?: number }];
}

// Business logic for fetching themes
const getAggregateThemes = async ({ queryKey }: QueryProps) => {
  const [_key, { token, days }] = queryKey;

  // If days param, limit the aggregation to number of days
  const params = days
    ? {
        startDate: dayjs().utc().subtract(days, "day").startOf("day").toDate(),
      }
    : {};

  return await useAxios({
    endpoint: "aggregateThemes",
    token: token,
    params: params,
  });
};

interface ParseProps {
  data: AxiosResponse;
  themeData: labeledObject[];
  isTrending?: boolean;
}

// Post-succesful fetch: data processing
// Convert hits to idCountPair[] and return it
const parseResults = ({ data, themeData, isTrending }: ParseProps) => {
  const hits = data?.data.response.themeOccurences.buckets;

  const themeList: idCountPair[] = [];
  for (const hit of hits) {
    themeList.push({ id: hit.key, count: hit.doc_count });
  }

  let trendingRumorIds: string[] | undefined;
  if (isTrending) {
    // If trending, only keep the first three objects
    trendingRumorIds = themeList?.slice(0, 3).map(({ id }) => id);
  } else trendingRumorIds = themeList?.map(({ id }) => id);

  const trendingThemes = constructAmpThemes({
    ids: trendingRumorIds,
    themeData: themeData,
    // Aggregate themes are
    isAlphabetized: true,
  });

  return trendingThemes;
};

interface Props {
  isTrending?: boolean;
  days?: number;
}

// State management for aggregating theme frequency
const useAggregateThemes = ({ isTrending, days }: Props) => {
  const token = useContext(ApiTokenContext);
  const themeData = useContext(ThemesContext);

  // Get Theme aggregations from DB
  const { isLoading, error, data } = useQuery({
    queryKey: [getAggregateThemesQuery, { token, days }],
    queryFn: getAggregateThemes,
    select: (data) => parseResults({ data, themeData, isTrending }),
    enabled: !!token,
  });

  // Show user a spinner or handle error
  if (isLoading) {
    return { isLoading: true };
  }

  if (error instanceof Error) {
    return { errorMessage: "NoContent" };
  }

  return { themeList: data };
};

export default useAggregateThemes;
