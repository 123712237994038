import { Box, CircularProgress, Stack } from "@mui/material";


// This function displays a circular loading indicator
export default function CircleLoading() {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
      }}
      flex={1}
    >
        <Stack
          direction="column"
          alignItems={"stretch"}
          justifyContent={"center"}
        >
          <CircularProgress color="secondary" />
        </Stack>
    </Box>
  );
}
