// Maximum Upload size (in MBs)
export const maxAttachmentSize = 7;

// Maximum text characters on evidence cards
export const textLength = 750;

// Maximum data returned per page
export const cardsPerPage = 20;
export const contentPerPage = 3;

// Trending period options (in days)
export const trendingPeriodOptions = [7, 14, 30, 90];

// URL Length on Content Cards
export const urlLengthCard = {
  xs: 35,
  md: 65,
};
