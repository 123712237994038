// MUI
import { Stack } from "@mui/material";
import AutocompleteMultiple from "../../addEvidence/AutocompleteMultiple";
import CancelSubmitButtonPair from "../../buttons/CancelSubmitButtonPair";
import { Evidence } from "../../../models/Evidence";

// Local

interface Props {
  updateFormData: (values: Record<string, string[]>) => void;
  handleEdit: () => void;
  submitEdits: (type: keyof Evidence, ids: string[]) => void;
  ids?: string[];
  type?: keyof Evidence;
}

// This function displays an user-editable UI for editing
// a specific property on an Evidence document
const EditEvidenceProperty = ({
  updateFormData,
  handleEdit,
  submitEdits,
  ids,
  type,
}: Props) => {
  // Submit edits, if inputs exist
  const handleClick = () => {
    if (type && ids) {
      submitEdits(type, ids);
    } else {
      console.log("Missing inputs! Not submitting");
    }
  };
  return (
    <Stack
      direction={"column"}
      display="flex"
      justifyContent={"space-between"}
      spacing={1}
    >
      {type && (
        <AutocompleteMultiple
          field={type}
          isFilter
          selectedValues={ids}
          updateFormData={updateFormData}
        />
      )}
      <CancelSubmitButtonPair submit={handleClick} cancel={handleEdit} />
    </Stack>
  );
};

export default EditEvidenceProperty;
