import { labeledObject } from "./interfaces";
import { sortByLabel } from "./alphabetize";
import { CountryDataProps, countryArray } from "../../models/Countries";

interface Props {
  data: labeledObject[];
  ids: string[];
  isAlphabetized?: boolean;
}

// Given Id, return the labels of idLabelDescription
export const idLookups = ({ data, ids, isAlphabetized }: Props) => {
  const lookupList: labeledObject[] = [];

  for (const id of ids) {
    const object = data.find((object) => object.id === id);
    if (object) {
      lookupList.push({
        id: object.id,
        label: object.label,
        description: object.description,
      });
    }
  }

  if (!isAlphabetized) {
    // Alphabetize and return
    const sortedList = sortByLabel(lookupList);
    return sortedList;
  } else {
    // Return unsorted
    return lookupList;
  }
};

interface IdLabelLookUpProps {
  id: string;
  data: { id: string; label: string }[];
}

// Given Id, return the label of idLabel
export const idLabelLookup = ({ id, data }: IdLabelLookUpProps): string => {
  if (id) {
    const index = data.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      return data[index].label;
    }
  }
  return "";
};

// Location lookup by key/id
export const locationLookup = (id: string): CountryDataProps => {
  const index = countryArray.findIndex((obj) => obj.id === id);
  return countryArray[index];
};
