// Global
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

// MUI
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import {
  COLORS,
  appMaxWidth,
  appMobileWidth,
} from "../../lib/theme/default";
import AmpLogo from "../landingPage/AmpLogo";
import UserMenu from "../user/UserMenu";
import AmpButton from "../buttons/AmpButton";
import { RouteOptions } from "../../lib/util/enums";

// Navbar component
const Navbar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();

  // Individual text nav options
  const navItems: RouteOptions[] = [
    RouteOptions.collection,
    RouteOptions.discover,
    RouteOptions.analyze,
  ];

  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box maxWidth={appMaxWidth} width={appMobileWidth}>
        <Grid container>
          <Grid
            xs={8}
            md={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            {/* AMP Logo */}
            <Link to={`/`}>
              <AmpLogo />
            </Link>
          </Grid>
          <Grid
            xs={0}
            md={6.5}
            display="flex"
            alignItems="center"
            justifyContent={"flex-end"}
          >
            {/* Map the text navigation items (desktop) */}
            {!isSmallScreen &&
              navItems.map((item, index) => (
                <Stack
                  direction="row"
                  key={index}
                  display="flex-inline"
                  alignItems={"center"}
                >
                  <Link to={item}>
                    <Typography
                      variant="h4"
                      sx={{
                        color:
                          item === location.pathname
                            ? COLORS.primaryBlue
                            : "auto",
                      }}
                    >
                      {t("viewName." + item)}
                    </Typography>
                  </Link>
                  {index < navItems.length - 1 ? (
                    <Typography
                      ml={2}
                      mr={2}
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 400,
                        color: COLORS.secondaryGray,
                        opacity: "40%",
                      }}
                    >
                      |
                    </Typography>
                  ) : null}
                </Stack>
              ))}
          </Grid>

          <Grid
            xs={0}
            md={2.5}
            display="flex"
            alignItems="center"
            justifyContent={"flex-end"}
          >
            {/* Add Rumors and User Menu (desktop) */}
            {!isSmallScreen && (
              <>
                <AmpButton
                  text="AddRumors"
                  onClick={() => navigate(RouteOptions.submit)}
                />
                <UserMenu />
              </>
            )}
          </Grid>
          <Grid
            xs={4}
            md={0}
            display="flex"
            alignItems="center"
            justifyContent={"flex-end"}
          >
            {/* Hamburger menu (mobile only) */}
            {isSmallScreen && <UserMenu isMobile={true} />}{" "}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Navbar;
