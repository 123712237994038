import { validate as uuidValidate } from "uuid";

export interface S3FilenameProps {
    // UUID v4 generated from the uuid library
    uuid: string;
    // For example ".jpg"
    fileExtension: string;
    // If there are multiple attachments for the same object we need a filename qualifier
    attachmentIndex?: number;
}

export function formatUniqueS3Filename({uuid, fileExtension, attachmentIndex}: S3FilenameProps): string {
    const startsWithPeriod = /^\./;
    if (startsWithPeriod.test(fileExtension) && uuidValidate(uuid)) {
        return attachmentIndex ? `${uuid}_${attachmentIndex}${fileExtension}` : `${uuid}${fileExtension}`;
    }
    throw new Error('Invalid parameters provided during S3 name generation.');
}