// Globals
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";

// Import MUI
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Import Local
import AmpLogo from "../components/landingPage/AmpLogo";
import AddEvidenceGuide from "../components/addEvidence/AddEvidenceGuide";
import AddEvidenceGuideStepsMobile from "../components/addEvidence/AddEvidenceGuideStepsMobile";
import AddEvidenceNavButtons from "../components/addEvidence/AddEvidenceNavButtons";
import AddEvidenceStepOne from "../components/addEvidence/AddEvidenceStepOne";
import AddEvidenceStepTwo from "../components/addEvidence/AddEvidenceStepTwo";
import AddEvidenceStepThree from "../components/addEvidence/AddEvidenceStepThree";
import AddEvidenceStepFour from "../components/addEvidence/AddEvidenceStepFour";
import AddEvidenceSubmitted from "../components/addEvidence/AddEvidenceSubmitted";
import MobileX from "../components/buttons/MobileX";
import { AmpDataSubmitTypes, EvidenceStatus, RouteOptions } from "../lib/util/enums";
import { ApiTokenContext } from "../contexts/InitContexts";
import { generateUniqueId } from "../lib/util/generators";
import { AddEvidenceFormData } from "../lib/addEvidenceForms/props";
import useBadges from "../hooks/useBadges";
import { Platforms } from "../models/Platform";
import useQuickAdd from "../hooks/useQuickAdd";
import AlertDialogSlide from "../components/AlertSlideDialog";
import { parseQuickAddDataForFormData } from "../lib/util/dataCleaning";
import cleanFormData from "../lib/addEvidenceForms/cleanFormData";
import submitData from "../lib/util/submitData";
import useStepHandler from "../hooks/useStepHandler";

// This function manages the entire input evidence workflow
const AddEvidence = () => {
  // Global hooks
  const { t } = useTranslation();
  const { user } = useAuth0();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const apiToken = useContext(ApiTokenContext);

  // Local hooks and state
  // badgeCount tracks user inputs over time (from DB)
  const { badgeCount, setBadgeCount } = useBadges();
  // stepHandler managers where user is in AddEvidence wizard
  const { stepHandler } = useStepHandler();
  // formData holds the new rumor data for submission to DB
  const [formData, setFormData] = useState({
    id: generateUniqueId(),
  } as AddEvidenceFormData);
  // QuickAdd accelerates submission through web scraping
  const { handleUseQuickAdd, quickAddCallback, quickAddData, quickAddError } =
    useQuickAdd();
  // Mutation submits data to OpenSearch
  const mutation = useMutation({
    mutationFn: submitData,
    onError: () => {
      throw new Error("Error submitting rumor to database, sorry!");
    },
    onSuccess: () => {
      // Update badge count, current step on mutation success or throw error
      setBadgeCount(badgeCount !== undefined ? badgeCount + 1 : 1);
      stepHandler.changeStep(stepHandler.currentStep + 1);
    },
  });

  // Update formData
  const updateFormData = (values: object) => {
    setFormData({ ...formData, ...values });
    // Log current state of form
    console.log("Form data:", { ...formData, ...values });
  };

  // Finish the submission, triggered when binary uploads complete
  const finishSubmit = (values: object) => {
    const finalFormData = { ...formData, authorId: user?.name, ...values };
    const cleanedData = cleanFormData({ formData: finalFormData });
    // Submit the data to Opensearch
    mutation.mutate({
      submitType: AmpDataSubmitTypes.Evidence,
      data: cleanedData,
      apiToken,
    });
  };

  // Reset parameters for a new submission
  const submitAgain = () => {
    mutation.reset();
    stepHandler.setIsSubmitting(false);
    setFormData({ id: generateUniqueId() });
    stepHandler.changeStep(1);
  };

  // Initiate quickAdd routine
  const triggerQuickAdd = (autoSubmit: boolean) => {
    handleUseQuickAdd(formData?.url, autoSubmit);
  };

  // If QuickAdd completes, redirect user based on result
  useEffect(() => {
    if (quickAddCallback === 200) {
      // Send user to badge page
      setBadgeCount(badgeCount !== undefined ? badgeCount + 1 : 1);
      stepHandler.changeStep(5);
    } else if (!!quickAddData) {
      // Update form with data, go to step 2
      updateFormData(parseQuickAddDataForFormData(quickAddData));
      stepHandler.changeStep(stepHandler.currentStep + 1);
      stepHandler.setIsSubmitting(false);
    }
  }, [quickAddCallback, quickAddData]);

  return (
    <Grid container minHeight={"100vh"}>
      <Grid md={0} xl={2} />
      <Grid xs={12} md={8} xl={6} justifyContent={"center"}>
        <Box paddingLeft={{ xs: 2, md: 5 }} paddingRight={{ xs: 2, md: 5 }}>
          <Stack>
            <Grid container justifyContent={{ xs: "center", md: "flex-start" }}>
              <Grid xs={2} md={0}></Grid>
              <Grid xs={8} md={12}>
                <AmpLogo />
              </Grid>

              <Grid
                xs={2}
                md={0}
                marginTop={1}
                display="flex"
                justifyContent={"flex-end"}
              >
                {/* Mobile cancel button */}
                {isSmallScreen && <MobileX target={RouteOptions.landing} />}
              </Grid>
              <Grid xs={12} md={0}>
                {/* Mobile Stepper */}
                {isSmallScreen && (
                  <AddEvidenceGuideStepsMobile
                    currentStep={stepHandler.currentStep}
                  />
                )}
              </Grid>
            </Grid>
            <Box marginTop={2}>
              <Typography variant="h1">
                {/* Header */}
                {stepHandler.currentStep < 5 &&
                  t("inputStepHeader." + stepHandler.currentStep)}
              </Typography>
            </Box>

            {/* Lazy loading for each of the pages in the addEvidence wizard */}
            {stepHandler.currentStep === 1 && (
              <AddEvidenceStepOne
                handleAllowNext={stepHandler.setAllowNext}
                updateFormData={updateFormData}
                formData={formData}
                stepDirection={stepHandler.changeStep}
              />
            )}
            {stepHandler.currentStep === 2 && (
              <AddEvidenceStepTwo
                handleAllowNext={stepHandler.setAllowNext}
                updateFormData={updateFormData}
                formData={formData}
              />
            )}
            {stepHandler.currentStep === 3 && (
              <AddEvidenceStepThree
                handleAllowNext={stepHandler.setAllowNext}
                updateFormData={updateFormData}
                formData={formData}
              />
            )}
            {stepHandler.currentStep === 4 && (
              <AddEvidenceStepFour
                handleAllowNext={stepHandler.setAllowNext}
                updateFormData={updateFormData}
                formData={formData}
                isSubmitting={stepHandler.isSubmitting}
                finishSubmit={finishSubmit}
              />
            )}
            {stepHandler.currentStep === 5 && (
              <AddEvidenceSubmitted
                submitAgain={submitAgain}
                badgeCount={badgeCount}
              />
            )}
            <Box marginTop={2} marginBottom={2}>
              {stepHandler.currentStep < 5 && (
                <AddEvidenceNavButtons
                  stepHandler={stepHandler}
                  isQuickAdd={
                    formData.isQuickAdd ||
                    (formData.platformObject === Platforms.TikTok &&
                      stepHandler.currentStep === 1)
                  }
                  triggerQuickAdd={triggerQuickAdd}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid xs={0} md={4} xl={4}>
        <AddEvidenceGuide currentStep={stepHandler.currentStep} />
      </Grid>
      <AlertDialogSlide
        title={"quickAddError.Title"}
        message={"quickAddError.Message"}
        confirm="Confirm"
        // Open on error message
        isOpen={!!quickAddError && stepHandler.currentStep === 1}
        // On confirm, close dialogue and step forward
        handleUserChoice={() => {
          stepHandler.setIsSubmitting(false);
          stepHandler.changeStep(stepHandler.currentStep + 1);
        }}
      />
    </Grid>
  );
};

export default AddEvidence;
