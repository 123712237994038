import { Theme } from "../../models/Theme";
import { labeledObject } from "./interfaces";

// Sort list of objects by "label" property
export const sortByLabel = (list: labeledObject[]) => {
  // Sort object lists
  list?.sort((a, b) => a.label.localeCompare(b.label));
  return list;
};

// Sort list of objects by "Group" property
export const sortThemeByGroup = (list: Theme[]) => {
  // Sort object lists
  list?.sort((a, b) => a.group.localeCompare(b.group));
  return list;
};
