// Import formik and formik-mui
import { Formik, Form } from "formik";

// Import MUI
import { Box } from "@mui/material";

// Import local
import { InputStepProps } from "../../lib/addEvidenceForms/props";
import TextFieldInput from "./TextFieldInput";
import AddAttachments from "./AddAttachments";

interface Props extends InputStepProps {
  isSubmitting: boolean;
  finishSubmit:  (attachments: object) => void;
}

const AddEvidenceStepFour = ({
  updateFormData,
  finishSubmit,
  formData,
  isSubmitting,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>

      <AddAttachments
        formData={formData}
        isSubmitting={isSubmitting}
        finishSubmit={finishSubmit}
      />

      <Formik
        initialValues={{
          notes: formData.notes ? formData.notes : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateFormData(values);
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <TextFieldInput field="notes" rows={3} rowsMobile={4} />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddEvidenceStepFour;
