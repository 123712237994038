import { useTranslation } from "react-i18next";

// MUI Imports
import Button from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BoltIcon from "@mui/icons-material/Bolt";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

type Props = {
  text: string;
  type?: string; // set to SECONDARY or ACCENT if desired
  symbol?: string; // add a MUI icon
  disabled?: boolean;
  fullWidth?: boolean;
  autoFocus?: boolean;
  onClick?: (event: any) => void;
};

// Return a primary, secondary, or accent button with TEXT label
// A "symbol" prop returns a MUI icon symbol after the text (needs hardcoding)

const AmpButton = ({
  text,
  type,
  symbol,
  disabled,
  fullWidth,
  autoFocus,
  onClick,
}: Props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  if (isSmallScreen) fullWidth = true;

  return (
    <Button
      variant={
        type === "secondary"
          ? "outlined"
          : type === "accent"
          ? "accent"
          : type === "text"
          ? "text"
          : "contained"
      }
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
    >
      {/* Symbols that appear BEFORE text */}
      {symbol === "arrowBack" && (
        <ArrowBackIcon sx={{ marginRight: "0.25rem", fontSize: "1rem" }} />
      )}
      {symbol === "check" && (
        <CheckIcon sx={{ marginRight: "0.25rem", fontSize: "1rem" }} />
      )}
      {/* TEXT on button */}
      {t("buttons." + text)}
      {/* Symbols that appear AFTER text */}
      {symbol === "arrowForward" && (
        <ArrowForwardIcon sx={{ marginLeft: "0.25rem", fontSize: "1rem" }} />
      )}
      {symbol === "arrowDown" && (
        <KeyboardArrowDownIcon
          sx={{ marginLeft: "0.25rem", fontSize: "1rem" }}
        />
      )}
      {symbol === "bolt" && (
        <BoltIcon sx={{ marginLeft: "0.25rem", fontSize: "1rem" }} />
      )}
      {symbol === "review" && (
        <ManageSearchIcon sx={{ marginLeft: "0.25rem", fontSize: "1rem" }} />
      )}
    </Button>
  );
};

export default AmpButton;
