import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro";

// Dayjs
import dayjs, { Dayjs } from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { EvidenceFilters } from "../../models/EvidenceFilters";
import { EvidenceAggregatedByDay } from "../../models/Evidence";
dayjs.extend(utcPlugin);
dayjs.extend(LocalizedFormat);

interface DateProps {
  date: Date;
  userLang: string;
  isShortDate?: boolean;
}

// This function returns the date in UTC time
// Formatted for the user's date style
export const getUtcDateLocalized = ({
  date,
  userLang,
  isShortDate,
}: DateProps): string => {
  // Convert language to locale format
  const locale = userLang === "fr" ? "fr-FR" : "en-US";

  return date.toLocaleDateString(locale, {
    timeZone: "UTC",
    year: isShortDate ? undefined : "numeric",
    month: isShortDate ? "short" : "long",
    day: "numeric",
  });
};

interface DateRangeProps {
  gte: dayjs.Dayjs;
  lte: dayjs.Dayjs;
}

// Get date range as a localized string
export const formatDateRangeString = ({ gte, lte }: DateRangeProps): string => {
  let dateString: string = "";

  // TODO: add in date formatting based on user pref for English v French
  // dayjs.locale('en')
  // dayjs.locale('fr')

  dateString = gte.utc().format("L") + " - " + lte.utc().format("L");

  // Assign a fixed name if it matches dateQuickOptions
  if (lte.isSame(dayjs().utc(), "day")) {
    const diffInDays = lte.diff(gte, "days");
    switch (diffInDays) {
      case 6:
        dateString = "Last7Days";
        break;
      case 29:
        dateString = "Last30Days";
        break;
      case 89:
        dateString = "Last90Days";
        break;
      default:
        if (
          gte
            .utc()
            .startOf("day")
            .isSame(lte.utc().subtract(6, "months").startOf("day"))
        ) {
          dateString = "Last6Months";
        } else if (
          gte
            .utc()
            .startOf("day")
            .isSame(lte.utc().subtract(1, "year").startOf("day"))
        ) {
          dateString = "LastYear";
        }

        break;
    }
  }

  return dateString;
};

// This list defines the options for pre-selected date range filters
export const dateQuickOptions: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs().utc().endOf("day");
      const startOfWeek = today.subtract(6, "day").startOf("day");
      return [startOfWeek, today];
    },
  },
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = dayjs().utc().endOf("day");
      const startOfWeek = today.subtract(29, "day").startOf("day");
      return [startOfWeek, today];
    },
  },
  {
    label: "Last 90 Days",
    getValue: () => {
      const today = dayjs().utc().endOf("day");
      const startOfWeek = today.subtract(89, "day").startOf("day");
      return [startOfWeek, today];
    },
  },
  {
    label: "Last 6 Months",
    getValue: () => {
      const today = dayjs().utc().endOf("day");
      const startOfWeek = today.subtract(6, "month").startOf("day");
      return [startOfWeek, today];
    },
  },
  {
    label: "Last Year",
    getValue: () => {
      const today = dayjs().utc().endOf("day");
      const startOfWeek = today.subtract(1, "year").startOf("day");
      return [startOfWeek, today];
    },
  },
];

interface ConstructFullDateRangeProps {
  data: EvidenceAggregatedByDay[];
  evidenceFilters: EvidenceFilters;
}

// Extend line chart to full date range
// If date histogram aggregations don't extend to user-defined start/end,
// Add days with count: 0
export const constructFullDateRange = ({
  data,
  evidenceFilters,
}: ConstructFullDateRangeProps) => {
  // Pad the beginning period of date range
  while (evidenceFilters.dateRange.gte <= dayjs(data[0].date).add(-1, "day")) {
    const firstDay = dayjs(data[0].date);
    const newDay = firstDay.add(-1, "day");
    data.unshift({ date: newDay.toDate(), count: 0 });
  }

  // Pad the ending period of date range
  while (
    evidenceFilters.dateRange.lte >=
    dayjs(data[data.length - 1].date).add(1, "day")
  ) {
    const lastDay = dayjs(data[data.length - 1].date);
    const newDay = lastDay.add(1, "day");
    data.push({ date: newDay.toDate(), count: 0 });
  }

  return data;
};

// Get the prefix for QuickAdd Attachments
export function generateQuickAddAttachmentPrefix(): string {
  return dayjs().utc().format("YYYY/MM/DD") + "/evidence/";
}

// Calculate days ago
export function getNDaysAgo(days = 29) {
  return dayjs().utc().subtract(days, "day").startOf("day")
}