import useAxios from "../../hooks/useAxios";

interface Props {
  presignedUrl: string;
  attachment: File;
}

// Build createPresignedUrl API call
const uploadAttachments = async ({presignedUrl, attachment}: Props) => {
  
    const response = await useAxios({
      method: "PUT",
      external: true,
      endpoint: presignedUrl,
      data: attachment,
      contentType: attachment.type
    });

  return response;

};

export default uploadAttachments;
