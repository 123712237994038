// Global
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Typography, CardActionArea, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Local
import { labeledObject } from "../../lib/util/interfaces";
import EvidenceMap from "../visualizations/EvidenceMap";
import useEvidenceFilters from "../../hooks/useEvidenceFilters";
import useEvidence from "../../hooks/useEvidence";
import { RouteOptions } from "../../lib/util/enums";
import CircleLoading from "../navigation/CircleLoading";

interface Props {
  data: labeledObject;
  days?: number;
}

// Display a Theme Card
const ThemeCard = ({ data, days }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Filter evidence by themeId
  const { evidenceFilters } = useEvidenceFilters({
    view: RouteOptions.landing,
    theme: data.id,
    days
  });

  // Get evidence aggregations
  const { chartData, isLoading } = useEvidence({
    evidenceFilters,
    isAggregationsOnly: true,
  });

  // Drilldown: Click to navigate into Analyze pre-filtered for Theme
  const handleClick = () => {
    navigate({
      pathname: RouteOptions.analyze,
      search: "?themeId=" + data.id + '&period=' + days,
    });
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {/* Mini-map */}
        <Grid xs={12} md={6}>
          {isLoading && <CircleLoading />}
          {!!chartData && <EvidenceMap data={chartData.mapData} isMinimap />}
        </Grid>

        <Grid xs={12} md={6}>
          <CardActionArea onClick={handleClick}>
            {/* Text area */}
            <Tooltip title={t("cards.tooltip.Drilldown")}>
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1">{data.label} </Typography>
              <Typography variant="body1">{data.description} </Typography>
            </Stack>
            </Tooltip>
          </CardActionArea>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThemeCard;
