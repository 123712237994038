// Global
import { useContext } from "react";

// MUI
import { Box, Typography } from "@mui/material";

// Local
import DotSpacer from "./DotSpacer";
import constructAmpThemes from "../../lib/util/constructAmpThemes";
import { ThemesContext } from "../../contexts/InitContexts";
import EditButton from "../buttons/EditButton";
import useEditEvidence from "../../hooks/useEditEvidence";

interface Props {
  ids: string[] | undefined;
  isQA?: boolean;
  evidenceId?: string;
}

// Format theme list for evidence card display
const ThemeList = ({ ids, isQA, evidenceId }: Props) => {
  // Local hooks
  const { values, isEditting, isLoading, handleEdit, editView } =
    useEditEvidence({
      type: "themeIds",
      ids,
      evidenceId: evidenceId || "",
    });

  // Global hooks
  const themeData = useContext(ThemesContext);
  const themes = constructAmpThemes({ ids: values, themeData });

  // If textList exists, map it
  // Add DotSpacer between themes
  return isLoading ? (
    isLoading
  ) : isEditting ? (
    editView
  ) : (
    <Box alignItems="center">
      {/* Default view */}
      {themes &&
        !isEditting &&
        themes.map((theme, index) => (
          <Box key={index} display="inline">
            <Typography variant="h6" display="inline" key={theme.id}>
              {theme.label}
            </Typography>
            {index === themes.length - 1 ? null : <DotSpacer />}
          </Box>
        ))}

      {isQA && <EditButton handleEdit={handleEdit} type="Themes" />}
    </Box>
  );
};

export default ThemeList;
