// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, SelectChangeEvent, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import useAggregateThemes from "../../hooks/useAggregateThemes";
import { labeledObject } from "../../lib/util/interfaces";
import AmpCard, { AmpCardTypes } from "../cards/AmpCard";
import CircleLoading from "../navigation/CircleLoading";
import ThemeCard from "../cards/ThemeCard";
import AmpDropdown from "../buttons/AmpDropdown";
import { trendingPeriodOptions } from "../../lib/configs";

// This component displays trending rumors
const TrendingRumors = () => {
  // Global
  const { t } = useTranslation();

  // Local
  const [trendingPeriod, setTrendingPeriod] = useState<number>(30);
  const { themeList, isLoading } = useAggregateThemes({
    isTrending: true,
    days: trendingPeriod,
  });

  // Set trending period
  const handleChange = (event: SelectChangeEvent) => {
    const value =
      typeof event.target.value === "string"
        ? Number(event.target.value)
        : event.target.value;
    setTrendingPeriod(value);
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        mb={2}
      >
        <Typography variant="subtitle2">{t("TrendingThemes")}</Typography>
        <AmpDropdown
          name="trending"
          values={trendingPeriodOptions}
          currentValue={trendingPeriod}
          hideLabel
          handleChange={handleChange}
        />
      </Stack>
      {isLoading && <CircleLoading />}
      {!isLoading && (
        <Grid container spacing={2} alignItems={"start"}>
          {themeList &&
            themeList.map((theme: labeledObject) => (
              <Grid xs={12} md={4} key={theme.id}>
                <AmpCard type={AmpCardTypes.theme} isMinicard>
                  <ThemeCard data={theme} days={trendingPeriod} />
                </AmpCard>
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
};

export default TrendingRumors;
