// Global
import { ReactNode } from "react";

// MUI
import { Box, Card, useMediaQuery, useTheme } from "@mui/material";

// Local
import {
  COLORS,
  cardMaxWidth,
  cardMinWidth,
  chartWidth,
  stripeWidth,
} from "../../lib/theme/default";

// AmpCard Types
export enum AmpCardTypes {
  evidence = "evidence",
  theme = "theme",
  content = "content",
  chart = "chart",
}

export interface AmpCardProps {
  type: AmpCardTypes;
  isMinicard?: boolean;
  children?: ReactNode;
}

// Container for cards on the AMP main stage
const AmpCard = ({ type, isMinicard, children }: AmpCardProps) => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Color coded by data type
  const marginColor = () => {
    if (type === AmpCardTypes.evidence) {
      return COLORS.primaryBlue;
    } else if (type === AmpCardTypes.theme) {
      return COLORS.airaPink;
    } else {
      return COLORS.nearBlack;
    }
  };

  return (
    <Card
      sx={{
        // Three sizes: Mobile, Full, Chart
        maxWidth:
          isSmallScreen || isMinicard
            ? "100%"
            : type === AmpCardTypes.chart
            ? "chartWidth"
            : cardMaxWidth,
        minWidth:
          isSmallScreen || isMinicard
            ? "100%"
            : type === AmpCardTypes.chart
            ? chartWidth
            : cardMinWidth,
        mb: 2,
      }}
    >
      <Box display="flex">
        {/* Color-coded stripe by type */}
        {type !== AmpCardTypes.chart && (
          <Box
            flexShrink={0}
            width={stripeWidth.toString() + "px"}
            sx={{ backgroundColor: marginColor }}
          />
        )}
        <Box flexGrow={1} p={1}>
          {children}
        </Box>
      </Box>
    </Card>
  );
};

export default AmpCard;
