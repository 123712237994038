// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import SubNav from "../components/navigation/SubNav";
import { RouteOptions } from "../lib/util/enums";
import AmpCard, { AmpCardTypes } from "../components/cards/AmpCard";
import {
  appMaxWidth,
  appMobileWidth,
  cardMaxWidth,
} from "../lib/theme/default";
import { useEvidence } from "../hooks/useEvidence";
import CircleLoading from "../components/navigation/CircleLoading";
import { Evidence } from "../models/Evidence";
import EvidenceCard from "../components/cards/EvidenceCard";
import useEvidenceFilters from "../hooks/useEvidenceFilters";
import LoadMoreButton from "../components/buttons/LoadMoreButton";
import DisplayResultCount from "../components/cards/DisplayResultCount";
import EvidenceFilterControls from "../components/filters/EvidenceFilterControls";
import ReviewControl from "../components/cards/ReviewControl";

interface Props {
  isQA?: boolean;
}

const MyCollection = ({ isQA }: Props) => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and consts
  const view = isQA ? RouteOptions.QA : RouteOptions.collection;
  const { evidenceFilters, updateEvidenceFilters, clearEvidenceFilters } =
    useEvidenceFilters({
      view,
    });

  const { evidenceList, resultCount, isLoading, error, manageNextPage } =
    useEvidence({
      evidenceFilters,
    });

  return (
    <Box>
      {/* SubNav Container */}
      <SubNav view={view}>
        <EvidenceFilterControls
          view={view}
          evidenceFilters={evidenceFilters}
          updateEvidenceFilters={updateEvidenceFilters}
          clearEvidenceFilters={clearEvidenceFilters}
        />
      </SubNav>

      <Box display="flex" justifyContent={"center"}>
        <Box
          minWidth={isSmallScreen ? appMobileWidth : cardMaxWidth}
          maxWidth={isSmallScreen ? appMobileWidth : appMaxWidth}
          justifyContent={"center"}
          mt={1}
        >
          {isLoading && <CircleLoading />}
          {!!error && error.toString()}

          {/* Display number of results to user  */}
          <DisplayResultCount count={resultCount} />

          {evidenceList &&
            // Map each evidence card
            evidenceList.map((evidence: Evidence) => (
              <Box display="flex" alignItems={"center"} key={evidence.id} width={1}>
                <AmpCard type={AmpCardTypes.evidence}>
                  <EvidenceCard evidence={evidence} isQA={isQA} />
                  {isSmallScreen && isQA && <ReviewControl evidence={evidence} />}
                </AmpCard>
                {!isSmallScreen && isQA && <ReviewControl evidence={evidence} />}
              </Box>
            ))}
          {!isLoading &&
            evidenceList &&
            evidenceList?.length > 0 &&
            manageNextPage && (
              <LoadMoreButton manageNextPage={manageNextPage} />
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default MyCollection;
