import { useEffect } from "react";
import { Formik, Form } from "formik";

// Import MUI and Formik
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Import local
import PlatformCard from "./PlatformCard";
import {
  AddEvidenceFormData,
  InputStepProps,
} from "../../lib/addEvidenceForms/props";
import { Platforms, PlatformTypes, Platform } from "../../models/Platform";
import TextFieldInput from "./TextFieldInput";
import QuickAdd from "./QuickAdd";
import { SocialMediaPostType } from "../../lib/util/enums";

interface Props extends InputStepProps {
  stepDirection: (direction: string) => void;
}

const AddEvidenceStepOne = ({
  handleAllowNext,
  updateFormData,
  formData,
  stepDirection,
}: Props) => {
  // Create an iterable list of Platforms
  const platformList = () => {
    const list: Platform[] = [];
    for (const platform in Platforms) {
      const key = platform as keyof typeof Platforms;
      list.push(Platforms[key]);
    }
    return list;
  };

  // Update form data with user selection
  const handleClick = (platformObject: Platform) => {
    // Ignore clicks on social media that miss the chips
    if (platformObject.type === PlatformTypes.SocialMedia) {
      return;
    }

    updateFormData({ platformObject: platformObject });
    if (platformObject.type !== PlatformTypes.UserDefined) {
      // Step forward, unless user defined
      stepDirection("f");
    }
  };

  // Update form data with user's social media selection
  const handleSocialMediaClick = (values: AddEvidenceFormData) => {
    updateFormData({ ...values });
    if (
      !values.platformObject?.isTypeQuickAdd ||
      values.socialMediaPostType === SocialMediaPostType.Comment
    ) {
      // Step forward, unless using QuickAdd
      stepDirection("f");
    }
  };

  const handleUserDefined = (userDefined: string) => {
    if (formData.platformObject) {
      const tempObject = formData.platformObject;
      tempObject.userDefined = userDefined;
      updateFormData({ platformObject: tempObject });
    }
  };

  // handle Allow Next Button
  useEffect(() => {
    if (
      formData.platformObject?.userDefined ||
      formData.url ||
      (formData.platformObject?.type &&
        formData.platformObject.type !== PlatformTypes.UserDefined &&
        formData.platformObject !== Platforms.TikTok)
    )
      handleAllowNext(true);
    else handleAllowNext(false);
  }, [formData]);

  return (
    <Box>
      <Grid container spacing={2} marginTop={2}>
        {/* Map the Platforms cards */}
        {platformList().map((platform, index) => (
          <Grid xs={4} md={3} key={index}>
            <Formik
              initialValues={{
                platform: formData.platformObject?.name || "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
              }}
            >
              <Form style={{ height: "100%" }}>
                <PlatformCard
                  platform={platform}
                  onClick={handleClick}
                  handleSocialMediaClick={handleSocialMediaClick}
                />
              </Form>
            </Formik>
          </Grid>
        ))}
        {formData.platformObject?.type === PlatformTypes.UserDefined && (
          <Grid xs={12}>
            <Formik
              initialValues={{
                userDefined: formData.platformObject.userDefined
                  ? formData.platformObject.userDefined
                  : "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                handleUserDefined(values.userDefined);
                setSubmitting(false);
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <TextFieldInput field="userDefined" required={true} />
                </Form>
              )}
            </Formik>
          </Grid>
        )}

        {/* QuickAdd takes a URL and prefills form data */}
        {formData.platformObject?.isTypeQuickAdd &&
          formData.socialMediaPostType !== SocialMediaPostType.Comment && (
            <Grid xs={12}>
              <QuickAdd
                handleAllowNext={handleAllowNext}
                updateFormData={updateFormData}
                formData={formData}
              />
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default AddEvidenceStepOne;
