// Globals
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Import local
import AlertDialogSlide from "../AlertSlideDialog";

// Import MUI
import { Stack, Box, useMediaQuery, useTheme } from "@mui/material";
import AmpButton from "../buttons/AmpButton";
import { RouteOptions } from "../../lib/util/enums";
import LinearLoader from "../navigation/LinearLoading";

interface InputNavProps {
  stepHandler: {
    currentStep: number;
    isSubmitting: boolean;
    allowNext: boolean;
    changeStep: (direction: string) => void;
    setIsSubmitting: (value: boolean) => void;
  };
  isQuickAdd: boolean;
  triggerQuickAdd: (autoSubmit: boolean) => void;
}

// Visualize the Next / Previous and Cancel buttons for Input Evidence
const AddEvidenceNavButtons = ({
  stepHandler,
  isQuickAdd,
  triggerQuickAdd,
}: InputNavProps) => {
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  // Confirm dialogue
  const handleUserChoice = (userChoice: string) => {
    if (userChoice === "confirm") navigate(RouteOptions.landing);
    setShowCancelDialog(false);
  };

  if (stepHandler.isSubmitting) {
    return <LinearLoader />;
  }

  return (
    <Stack direction={"row"} justifyContent="space-between">
      {/* Cancel button and dialogue (desktop only) */}
      {!isSmallScreen && (
        <Box display={"inline"}>
          <AmpButton
            type="secondary"
            text="Cancel"
            onClick={() => setShowCancelDialog(true)}
          />
          <AlertDialogSlide
            title={"cancelInputTitle"}
            message={"cancelInputMessage"}
            isOpen={showCancelDialog}
            showCancel
            handleUserChoice={handleUserChoice}
          />
        </Box>
      )}{" "}
      {/* Previous, Next, Submit buttons */}
      <Box
        display={{ xs: "flex", md: "flex" }}
        width={{ xs: 1 }}
        justifyContent={{ xs: "start", md: "flex-end" }}
      >
        {((!isQuickAdd && stepHandler.currentStep > 1) ||
          stepHandler.currentStep > 2) && (
          <Box display={"inline"} marginRight={4} width={{ xs: 1, md: "auto" }}>
            <AmpButton
              text="Previous"
              type="secondary"
              symbol="arrowBack"
              onClick={() => stepHandler.changeStep("b")}
            />
          </Box>
        )}
        {stepHandler.currentStep > 1 && stepHandler.currentStep < 4 && (
          <Box display={"inline"} width={{ xs: 1, md: "auto" }}>
            <AmpButton
              text="Next"
              symbol="arrowForward"
              disabled={!stepHandler.allowNext}
              onClick={() => stepHandler.changeStep("f")}
            />
          </Box>
        )}
        {/* If on step 4, SUBMIT instead of NEXT button */}
        {stepHandler.currentStep === 4 && (
          <AmpButton
            text="Submit"
            type="accent"
            symbol="check"
            disabled={!stepHandler.allowNext}
            onClick={() => stepHandler.setIsSubmitting(true)}
          />
        )}
        {/* If step 1 and Platform is QuickAdd, show QuickAdd options */}
        {isQuickAdd && stepHandler.currentStep === 1 && (
          <Box display={"inline"} marginRight={4} width={{ xs: 1, md: "auto" }}>
            <AmpButton
              text="QuickAdd"
              type="secondary"
              symbol="bolt"
              disabled={!stepHandler.allowNext}
              onClick={() => {
                stepHandler.setIsSubmitting(true);
                // Initiate QuickAdd with autoSubmit
                triggerQuickAdd(true);
              }}
            />
          </Box>
        )}
        {isQuickAdd && stepHandler.currentStep === 1 && (
          <Box display={"inline"} width={{ xs: 1, md: "auto" }}>
            <AmpButton
              text="QuickAddReview"
              symbol="review"
              disabled={!stepHandler.allowNext}
              onClick={() => {
                stepHandler.setIsSubmitting(true);
                // Initiate QuickAdd without autoSubmit
                triggerQuickAdd(false);
              }}
            />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default AddEvidenceNavButtons;
