import { labeledObject } from "../lib/util/interfaces";

export const africanLanguages: labeledObject[] = [
  { label: "Afrikaans", id: "af" },
  { label: "Akan", id: "ak" },
  { label: "Amharic", id: "am" },
  { label: "Arabic", id: "ar" },
  { label: "Bambara", id: "bm" },
  { label: "Berber", id: "ber" },
  { label: "Chichewa", id: "ny" },
  { label: "Comorian", id: "swb" },
  { label: "Crioulo", id: "kea" },
  { label: "Dioula", id: "dyo" },
  { label: "Dinka", id: "din" },
  { label: "Efik", id: "efi" },
  { label: "English", id: "en" },
  { label: "Ewe", id: "ee" },
  { label: "Fang", id: "fan" },
  { label: "Fon", id: "fon" },
  { label: "French", id: "fr" },
  { label: "Fula", id: "ff" },
  { label: "Gikuyu", id: "ki" },
  { label: "Hausa", id: "ha" },
  { label: "Igbo", id: "ig" },
  { label: "Kabiye", id: "kbp" },
  { label: "Kanuri", id: "kr" },
  { label: "Kikongo", id: "kg" },
  { label: "Kinyarwanda", id: "rw" },
  { label: "Kirundi", id: "rn" },
  { label: "Kongo", id: "kg" },
  { label: "Kpelle", id: "kpe" },
  { label: "Lingala", id: "ln" },
  { label: "Luba-Katanga", id: "lu" },
  { label: "Luhya", id: "luy" },
  { label: "Luo", id: "luo" },
  { label: "Malagasy", id: "mg" },
  { label: "Mandinka", id: "mnk" },
  { label: "Mende", id: "men" },
  { label: "Mossi", id: "mos" },
  { label: "Ndebele", id: "nr" },
  { label: "Nuer", id: "nus" },
  { label: "Oromo", id: "om" },
  { label: "Portuguese", id: "pt" },
  { label: "Pulaar", id: "ff" },
  { label: "Rundi", id: "rn" },
  { label: "Sango", id: "sg" },
  { label: "Sena", id: "seh" },
  { label: "Shona", id: "sn" },
  { label: "Siswati", id: "ss" },
  { label: "Somali", id: "so" },
  { label: "Soninke", id: "snk" },
  { label: "Sotho", id: "st" },
  { label: "Spanish", id: "es" },
  { label: "Swahili", id: "sw" },
  { label: "Tigrinya", id: "ti" },
  { label: "Tiv", id: "tiv" },
  { label: "Tshiluba", id: "lua" },
  { label: "Tswana", id: "tn" },
  { label: "Tumbuka", id: "tum" },
  { label: "Wolof", id: "wo" },
  { label: "Xhosa", id: "xh" },
  { label: "Yoruba", id: "yo" },
  { label: "Zulu", id: "zu" },
];
