// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import useContentCards from "../../hooks/useContentCards";
import { Content } from "../../models/Content";
import AmpCard, { AmpCardTypes } from "../cards/AmpCard";
import { appMaxWidth, appMobileWidth } from "../../lib/theme/default";
import CircleLoading from "../navigation/CircleLoading";
import DisplayResultCount from "../cards/DisplayResultCount";
import LoadMoreButton from "../buttons/LoadMoreButton";
import ContentCard from "../cards/ContentCard";
import { AmpDataSubmitTypes } from "../../lib/util/enums";

const ViewContentCards = () => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks
  const { contentList, resultCount, isLoading, error, manageNextPage } =
    useContentCards();

  return (
    <Box
      maxWidth={isSmallScreen ? appMobileWidth : appMaxWidth}
      justifyContent={"center"}
      mt={1}
    >
      {isLoading && <CircleLoading />}
      {!!error && error.toString()}

      {/* Display number of results to user  */}
      {!!resultCount && (
        <DisplayResultCount
          count={resultCount}
          dataType={AmpDataSubmitTypes.Content}
        />
      )}

      {contentList &&
        // Map each content card
        contentList.map((content: Content) => (
          <AmpCard type={AmpCardTypes.content} key={content.id}>
            <ContentCard data={content} />
          </AmpCard>
        ))}

      {/* Load more button */}
      {!isLoading &&
        contentList &&
        contentList?.length > 0 &&
        manageNextPage && <LoadMoreButton manageNextPage={manageNextPage} />}
    </Box>
  );
};

export default ViewContentCards;
