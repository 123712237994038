// Local
import useAxios from "../../hooks/useAxios";
import { FlagForReviewData } from "../../hooks/useFlagForReview";
import { AddContentFormData } from "../../models/Content";
import { AddEvidenceFormData } from "../addEvidenceForms/props";
import { AmpDataSubmitTypes } from "./enums";

interface Props {
  submitType: AmpDataSubmitTypes;
  data: AddContentFormData | FlagForReviewData | AddEvidenceFormData;
  apiToken: string;
}

// Biz logic: Submit evidence to /addEvidence endpoint
const submitData = async ({ submitType, data, apiToken }: Props) => {
  return await useAxios({
    token: apiToken,
    method: "POST",
    endpoint:
      submitType === "evidence"
        ? "addEvidence"
        : submitType === "content"
        ? "addContent"
        : submitType === "flagForReview"
        ? "sendEmail"
        : undefined,
    data: data,
  });
};

export default submitData;
