// Global
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import {
  COLORS,
  appMobileWidth,
  appMaxWidth,
  subNavHeight,
} from "../../lib/theme/default";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  view: RouteOptions;
  children?: ReactNode;
}

// Component displays the SubNavigation
// Space for children with search and filter components
const SubNav = ({ view, children }: Props) => {
  // Global hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isQA = view === RouteOptions.QA;

  return (
    <Box
      sx={{
        backgroundColor: isSmallScreen ? COLORS.backgroundGray : COLORS.white,
        display: "flex",
        justifyContent: "center",
        marginTop: "1px",
        minHeight: isSmallScreen ? subNavHeight.sm : subNavHeight.md,
      }}
    >
      <Grid
        container
        mt={isSmallScreen ? 1 : 0.5}
        mb={isSmallScreen ? 0 : 0.5}
        alignItems={"center"}
        sx={{
          maxWidth: appMaxWidth,
          width: appMobileWidth,
        }}
      >
        {/* Mobile & Desktop: View Name */}
        {/* If no children, full width */}
        <Grid
          xs={!children ? 12 : 7}
          md={!children ? 12 : isQA ? 3 : 4}
        >
          <Typography variant="h1">{t("viewName." + view)}</Typography>
        </Grid>

        {children}
      </Grid>
    </Box>
  );
};

export default SubNav;
