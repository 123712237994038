// Platform class
export class Platform {
  type: PlatformTypes;
  name: string;
  icon?: string;
  userDefined?: string;
  isTypeQuickAdd?: boolean;

  constructor(
    type: PlatformTypes,
    name: string,
    isTypeQuickAdd: boolean,
    icon?: string
  ) {
    this.type = type;
    this.name = name;
    this.isTypeQuickAdd = isTypeQuickAdd;
    this.icon = icon;
  }
}

// Platform Types
export enum PlatformTypes {
  Media = "Media",
  Other = "Other",
  SocialMedia = "SocialMedia",
  UserDefined = "UserDefined",
}

// Platforms Data
export const Platforms = {
  // First quad
  Radio: new Platform(PlatformTypes.Media, "Radio", false, "Radio.svg"),
  TV: new Platform(PlatformTypes.Media, "TV", false, "TV.svg"),
  OnlineNews: new Platform(
    PlatformTypes.Media,
    "OnlineNews",
    false,
    "News.svg"
  ),
  WhatsApp: new Platform(
    PlatformTypes.Other,
    "WhatsApp",
    false,
    "WhatsApp.svg"
  ),

  // Second quad
  Facebook: new Platform(
    PlatformTypes.SocialMedia,
    "Facebook",
    false,
    "Facebook.svg"
  ),
  Twitter: new Platform(
    PlatformTypes.SocialMedia,
    "Twitter",
    false,
    "Twitter.svg"
  ),
  TikTok: new Platform(PlatformTypes.SocialMedia, "TikTok", true, "TikTok.svg"),
  YouTube: new Platform(
    PlatformTypes.SocialMedia,
    "YouTube",
    false,
    "YouTube.svg"
  ),

  // Third quad
  Instagram: new Platform(
    PlatformTypes.SocialMedia,
    "Instagram",
    false,
    "Instagram.svg"
  ),
  Telegram: new Platform(
    PlatformTypes.Other,
    "Telegram",
    false,
    "Telegram.svg"
  ),

  WordOfMouth: new Platform(
    PlatformTypes.Other,
    "WordOfMouth",
    false,
    "WordOfMouth.svg"
  ),

  Other: new Platform(PlatformTypes.UserDefined, "Other", false),
};
