// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

// Local
import AmpButton from "../buttons/AmpButton";
import { Evidence } from "../../models/Evidence";
import { getUtcDateLocalized } from "../../lib/util/dateFormatters";
import DotSpacer from "./DotSpacer";
import ThemeList from "./ThemeList";
import IssuePills from "./IssuePills";
import EvidenceAttachments from "./EvidenceAttachments";
import TranslateButton from "./TranslateButton";
import SourceCountry from "./SourceCountry";
import useEvidenceText from "../../hooks/useEvidenceText";
import LinkIcon from "./LinkIcon";
import { smallFont } from "../../lib/theme/default";
import AmpDrawer from "../drawers/AmpDrawer";
import EvidenceDrawer from "../drawers/evidence/EvidenceDrawer";
import useAmpDrawer from "../../hooks/useAmpDrawer";
import { AmpDrawerState } from "../../lib/util/enums";
import FlagForReview from "./FlagForReview";
import { AmpCardTypes } from "./AmpCard";
import SaveToCollection from "./SaveToCollection";
import DisplayInteractions from "./DisplayInteractions";

interface Props {
  evidence: Evidence;
  isDetailView?: boolean;
  isQA?: boolean;
}

const EvidenceCard = ({ evidence, isDetailView, isQA }: Props) => {
  // Global Hooks
  const { t, i18n } = useTranslation();
  const userLang = i18n.language;
  const theme = useTheme();
  const isSmallScreen =
    useMediaQuery(theme.breakpoints.down("md")) || isDetailView;

  // Local hooks
  const { text, translationState, handleTranslationToggle } = useEvidenceText({
    evidence,
    userLang,
    isDetailView,
  });
  const { drawerState, setDrawerState } = useAmpDrawer();

  // Format date
  const localDate = getUtcDateLocalized({ date: evidence.date, userLang });

  // Calculate hasAttachments boolean
  const hasAttachments =
    evidence?.attachments && evidence.attachments.length > 0;

  return (
    <>
      <Stack alignItems="stretch">
        {/* Card Header */}
        <Grid container alignItems={"center"}>
          <Grid md="auto">
            {/* Platform */}
            <Stack direction="row" display="flex" alignItems={"center"}>
              <Typography variant="h3" display="inline">
                {evidence.platform}
              </Typography>
              {!isSmallScreen && (
                <>
                  {/* Date and Interactions (desktop only) */}
                  <DotSpacer wide />
                  <Typography variant="h4" display="inline">
                    {localDate}
                  </Typography>
                  <DisplayInteractions count={evidence.interactionCount} />
                  <DisplayInteractions isViewCount count={evidence.viewCount} />
                </>
              )}
            </Stack>
          </Grid>
          <Grid md="auto" xsOffset={"auto"}>
            <Stack
              direction="row"
              spacing={0.5}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {/* Upper-right Icon Array */}
              <TranslateButton
                translationState={translationState}
                handleTranslationToggle={handleTranslationToggle}
              />
              <LinkIcon url={evidence.url} />
              <FlagForReview type={AmpCardTypes.evidence} id={evidence.id} />
              <SaveToCollection type={AmpCardTypes.evidence} id={evidence.id} />
            </Stack>
          </Grid>
          {isSmallScreen && (
            <Grid xs={12} mt={1}>
              {/* Date and Interactions (mobile only) */}
              <Stack direction="row" display="flex" alignItems={"center"}>
                <Typography variant="h4" display="inline" fontSize={smallFont}>
                  {localDate}
                </Typography>
                <DisplayInteractions count={evidence.interactionCount} />
                <DisplayInteractions isViewCount count={evidence.viewCount} />
              </Stack>
            </Grid>
          )}
          <Grid xs={12} mt={1}>
            {/* Themes */}
            <ThemeList
              ids={evidence.themeIds}
              isQA={isQA}
              evidenceId={evidence.id}
            />
          </Grid>

          <Stack direction="row" alignItems="baseline" spacing={1}></Stack>
        </Grid>

        {/* Card Body */}
        <Grid container mt={1}>
          {hasAttachments && (
            <Grid
              xs={12}
              md={isDetailView ? 12 : 4}
              pb={isSmallScreen ? 1 : "auto"}
            >
              <EvidenceAttachments attachments={evidence.attachments} />
            </Grid>
          )}
          {/* Evidence text, original post, and source country */}
          <Grid
            xs={12}
            md={hasAttachments && !isDetailView ? 8 : 12}
            pl={isSmallScreen ? "auto" : 3}
            pr={isSmallScreen ? "auto" : 3}
          >
            <Box>
              {evidence?.originalPost && (
                <Typography variant="body2" mb={0.5}>
                  {t("cards.OriginalPost")} {evidence.originalPost}
                </Typography>
              )}
              <Typography variant="body1">{text}</Typography>
            </Box>
            <Box mt={1}>
              <SourceCountry
                countries={evidence.countries}
                isQA={isQA}
                evidenceId={evidence.id}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Card Footer */}
        <Grid container alignItems="end" mt={1}>
          <Grid xs={12} md={isDetailView ? 12 : 7}>
            {/* Issues */}
            <IssuePills
              ids={evidence.issueIds}
              isQA={isQA}
              evidenceId={evidence.id}
            />
          </Grid>

          {/* Buttons for See Details, See Responses */}
          {!isDetailView && (
            <Grid xs={12} md={5} mt={1}>
              <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                <AmpButton
                  text={t("Evidence.Details")}
                  type="secondary"
                  onClick={() => setDrawerState(AmpDrawerState.EvidenceDetails)}
                />
                <AmpButton
                  text={t("Evidence.Response")}
                  onClick={() => setDrawerState(AmpDrawerState.Responses)}
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>

      {/* Toggle components */}
      {!!drawerState && (
        <AmpDrawer
          drawerState={drawerState}
          handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
        >
          <EvidenceDrawer
            initialOpenTab={drawerState}
            evidence={evidence}
            isQA={isQA}
          />
        </AmpDrawer>
      )}
    </>
  );
};

export default EvidenceCard;
