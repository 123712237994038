// Import formik and formik-mui
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Import MUI
import { Box } from "@mui/material";

// Import local
import { InputStepProps } from "../../lib/addEvidenceForms/props";
import { SocialMediaPostType } from "../../lib/util/enums";
import AutocompleteMultiple from "./AutocompleteMultiple";
import TextFieldInput from "./TextFieldInput";
import { PlatformTypes, Platforms } from "../../models/Platform";
import { numberFormSize } from "../../lib/theme/default";
import { interactionsSchema } from "../../lib/util/validators";

const AddEvidenceStepThree = ({ updateFormData, formData }: InputStepProps) => {
  // Reusable constants for each type of interactions
  const likeCount = (
    <TextFieldInput
      field="likeCount"
      width={numberFormSize}
      inline={true}
      noTitle={formData.platformObject === Platforms.Twitter ? true : false}
    />
  );
  const commentCount = (
    <TextFieldInput
      field="commentCount"
      width={numberFormSize}
      inline={true}
      noTitle={true}
    />
  );
  const shareCount = (
    <TextFieldInput
      field="shareCount"
      width={numberFormSize}
      inline={true}
      noTitle={true}
    />
  );
  const retweetCount = (
    <TextFieldInput
      field="retweetCount"
      width={numberFormSize}
      inline={true}
      noTitle={false}
    />
  );

  const viewCount = (
    <TextFieldInput
      field="viewCount"
      width={numberFormSize}
      inline={true}
      noTitle={true}
    />
  );

  // Custom sequence of interactions specific to platform
  // This matches the sequence the user sees online for fast, easy data input
  const sequence = {
    default: (
      <>
        {/* Use for Facebook and default */}
        {likeCount} {commentCount} {shareCount} {viewCount}
      </>
    ),
    twitter: (
      <>
        {/* TODO: add Twitter bookmarks */}
        {retweetCount} {likeCount} {viewCount}
      </>
    ),
    youtube: (
      <>
        {likeCount} {viewCount} {commentCount}
      </>
    ),
    telegram: (
      <>
        {likeCount} {viewCount}
      </>
    ),
    instagram: (
      <>
        {likeCount} {commentCount}
      </>
    ),
  };

  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>
      <Formik
        initialValues={{
          likeCount: formData.likeCount ? formData.likeCount : "",
          commentCount: formData.commentCount ? formData.commentCount : "",
          shareCount: formData.shareCount ? formData.shareCount : "",
          viewCount: formData.viewCount ? formData.viewCount : "",
          retweetCount: formData.retweetCount ? formData.retweetCount : "",
          
        }}
        // Validate interactions

        validationSchema={Yup.object({
          likeCount: interactionsSchema,
          commentCount: interactionsSchema,
          shareCount: interactionsSchema,
          viewCount: interactionsSchema,
          retweetCount: interactionsSchema,
        })}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          updateFormData(values);
          setSubmitting(false);
          // }, 200);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>


            <AutocompleteMultiple
              field="issueIds"
              selectedValues={formData.issueIds}
              updateFormData={updateFormData}
            />

            <AutocompleteMultiple
              field="themeIds"
              selectedValues={formData.themeIds}
              updateFormData={updateFormData}
            />

            {/* If platformObject type === Social media 
             Display interactions boxes. Sequenced per specific platform */}
            {formData.platformObject?.type === PlatformTypes.SocialMedia &&
              (formData.platformObject === Platforms.Twitter
                ? sequence.twitter
                : formData.platformObject === Platforms.YouTube
                ? sequence.youtube
                : formData.platformObject === Platforms.Telegram
                ? sequence.telegram
                : formData.platformObject === Platforms.Instagram
                ? sequence.instagram
                : sequence.default)}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddEvidenceStepThree;
