// Global
import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

// Local
import { ApiTokenContext } from "../contexts/InitContexts";
import useAxios from "./useAxios";
import { cardsPerPage } from "../lib/configs";
import { Content } from "../models/Content";
import { content } from "../lib/api/keyConstants";
import { cleanDuplicatesFromObjectArrays } from "../lib/util/dataCleaning";

interface QueryProps {
  queryKey: [
    string,
    {
      token: string;
    }
  ];
  pageParam?: number | undefined;
}

// Call Axios to get data from content index
const getContent = async ({ queryKey, pageParam = 0 }: QueryProps) => {
  const [_key, { token }] = queryKey;

  const response = await useAxios({
    token: token,
    endpoint: "getContent",
  });

  return response;
};

interface ParseProps {
  data: { pages: AxiosResponse[] };
}

const parseResults = ({ data }: ParseProps) => {
  const contentList: Content[] = [];

  // Loop through "pages" of data (Infinite Query array)
  for (const page of data.pages) {
    const hits = page.data.response.body.hits.hits;
    for (const hit of hits) {
      const contentCard = new Content(hit);
      cleanDuplicatesFromObjectArrays(contentCard);
      contentList.push(contentCard)
    }
  }

  // Return content cards
  return {
    contentList,
  };
};

// State management for Content
// Infinite scroll UX
const useContentCards = () => {
  const token = useContext(ApiTokenContext);

  const {
    isLoading,
    isFetching,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [content, { token }],
    queryFn: getContent,
    // SELECT is commented out due to Typing error
    // select: (result) => parseResults(result),

    // Calculate pageParam or returned undefined to disable LoadMore
    getNextPageParam: (lastPage, pages) => {
      const isMoreData =
        lastPage.data.response.body.hits.total.value >
        pages.length * cardsPerPage;
      return isMoreData ? pages.length : undefined;
    },
    enabled: !!token,
  });

  // Show user a spinner or handle error
  if (isLoading || isFetching) {
    return { isLoading };
  }

  if (error instanceof Error) {
    // TODO: Update this to handle missing content gracefully
    return { error };
  }

  if (data) {
    const parsedResults = parseResults({ data });
    const resultCount: number | undefined =
      data?.pages[0].data.response.body.hits.total.value.toString();

    // Return parsed data
    return {
      contentList: parsedResults.contentList,
      resultCount: resultCount,
      manageNextPage: { hasNextPage, isFetchingNextPage, fetchNextPage },
    };
  }
  return {};
};

export default useContentCards;
