// Global
import { FacebookEmbed, TwitterEmbed } from "react-social-media-embed";

// MUI
import { Stack, useMediaQuery, useTheme } from "@mui/material";

// Local
import { Content } from "../../../models/Content";
import useSocialShare from "../../../hooks/useSocialShare";
import { responseCardSize } from "../../../lib/theme/default";

interface Props {
  content: Content;
}

// This component returns a single share option
const ContentItem = ({ content }: Props) => {
  if (!content.binaryPath) {
    return null;
  }

  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Hook to manage share button
  const shareButtons = useSocialShare(content);

  return (
    <Stack spacing={1}>
      {/* Displays the response content for the source platform */}
      {content.binaryPath.toLowerCase().includes("twitter.com") && (
        <TwitterEmbed
          url={content.binaryPath}
          width={isSmallScreen ? "100%" : responseCardSize.Twitter}
        />
      )}
      {content.binaryPath.toLowerCase().includes("facebook.com") && (
        <FacebookEmbed
          url={content.binaryPath}
          width={isSmallScreen ? "100%" : responseCardSize.Facebook}
          height={400}
        />
      )}
      {shareButtons}
    </Stack>
  );
};
export default ContentItem;
