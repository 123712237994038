// Global
import { ReactNode } from "react";

// MUI
import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { AmpDrawerState } from "../../lib/util/enums";

interface Props {
  drawerState: AmpDrawerState;
  children: ReactNode;
  handleCloseDrawer: () => void;
}
const AmpDrawer = ({ drawerState, children, handleCloseDrawer }: Props) => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and constants
  // Desktop only: Size AmpDrawer wide/skinny based on drawerstate
  const wideDrawer: boolean = !!(
    drawerState === AmpDrawerState.Responses ||
    drawerState === AmpDrawerState.EvidenceDetails
  );

  if (drawerState === AmpDrawerState.Closed) {
    return null;
  }

  return (
    <Drawer
      anchor={isSmallScreen ? "bottom" : "right"}
      open={true}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          width: isSmallScreen ? "auto" : wideDrawer ? "500px" : "30vw",
          height: isSmallScreen ? "80vh" : 1,
        },
      }}
    >
      {/* Close button */}
      <Box
        onClick={handleCloseDrawer}
        zIndex={1}
        sx={{
          position: "fixed",
          top: isSmallScreen ? "calc(20vh + 16px)" : 16,
          right: 16,
        }}
      >
        <ClearIcon />{" "}
      </Box>
      {/* Children */}
      {children}
    </Drawer>
  );
};

export default AmpDrawer;
