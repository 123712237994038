// Global
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

// MUI
import { Box, Typography } from "@mui/material";

// Local
import { COLORS, smallFont } from "../../lib/theme/default";
import DotSpacer from "./DotSpacer";
import getCountryData, {
  CountryListProps,
} from "../../lib/util/getCountryData";
import useEditEvidence from "../../hooks/useEditEvidence";
import EditButton from "../buttons/EditButton";

interface Props {
  countries: string[] | undefined;
  isQA?: boolean;
  evidenceId: string;
}

// Return an alphabatized list of source country names
const SourceCountry = ({ countries, isQA, evidenceId }: Props) => {
  const { t } = useTranslation();

  if (!countries && !isQA) {
    return null;
  }

  // Local hooks
  const { values, isEditting, handleEdit, editView, isLoading } =
    useEditEvidence({
      type: "countries",
      ids: countries,
      evidenceId,
    });

  // Fetch and alphabetize country label and flag
  const countryList: CountryListProps[] = getCountryData({ countries: values });

  // Display isLoading or isEditting as needed
  return isLoading ? (
    isLoading
  ) : isEditting ? (
    editView
  ) : (
    // Default: Display source country component
    <Box mt={1}>
      <Typography variant="h4" display="inline" sx={{ fontSize: smallFont }}>
        {t("cards.EvidenceLocation")}:
      </Typography>{" "}
      {!!countries && countryList.map((country, index) => (
        <Box display="inline" key={index}>
          {/* Insert country flag, or if none, DotSpacer */}
          {country.flag ? (
            <Box className={country.flag} sx={{ fontSize: smallFont }} />
          ) : (
            <DotSpacer />
          )}{" "}
          <Typography
            variant="body1"
            display="inline"
            color={COLORS.secondaryGray}
            pr={1}
          >
            {" "}
            {country.label}
          </Typography>
        </Box>
      ))}
      {isQA && <EditButton handleEdit={handleEdit} type="Location" />}
    </Box>
  );
};

export default SourceCountry;
