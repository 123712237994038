import { Typography } from "@mui/material";

interface Props {
  wide?: boolean;
}

export default function DotSpacer({ wide }: Props) {
  return (
    <Typography variant="h4" mx={wide ? 0.5 : 0.25} display={"inline"}>
      {" "}
      &#183;{" "}
    </Typography>
  );
}
