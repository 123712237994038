// Global
import { useTranslation } from "react-i18next";

// MUI
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";

// Local
import { COLORS } from "../../lib/theme/default";

interface Props {
  name: string;
  hideLabel?: boolean;
  values: string[] | number[];
  currentValue: string | number;
  handleChange: (event: SelectChangeEvent) => void;
}

const AmpDropdown = ({
  currentValue,
  values,
  hideLabel,
  name,
  handleChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      {!hideLabel && (
        <InputLabel>{t("buttons." + name + ".GuideText")}</InputLabel>
      )}
      <Select
        value={
          typeof currentValue === "number"
            ? currentValue.toString()
            : currentValue
        }
        label={hideLabel ? undefined : t("buttons." + name + ".GuideText")}
        onChange={handleChange}
        size="small"
        displayEmpty
        sx={{
          backgroundColor: COLORS.backgroundGray,
        }}
      >
        {values.map((value, index) => (
          <MenuItem value={value} key={index}>
            {t("buttons." + name + "." + value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AmpDropdown;
