import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI Imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import AmpButton from "./buttons/AmpButton";

// Local imports

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  title: string;
  message: string;
  confirm?: string;
  cancel?: string;
  showCancel?: boolean;
  isOpen: boolean;
  handleUserChoice: (userChoice: string) => void;
};

// A prop to display an alert dialog
// Must pass title and messsage of alert
// Defaults to Yes/No buttons but Props can override
export default function AlertDialogSlide({
  title,
  message,
  confirm,
  cancel,
  showCancel,
  isOpen,
  handleUserChoice,
}: Props) {
  const { t, i18n } = useTranslation();
  // Translation key builder
  const alertTitle = "alertSlideDialog." + title;
  const alertMessage = "alertSlideDialog." + message;

  // Button defaults
  const confirmButton = confirm || "Yes";
  const cancelButton = cancel || "No";

  // Close dialogue and proceed or cancel
  const handleClose = (userChoice: string) => {
    // setOpen(false);
    handleUserChoice(userChoice);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose("cancel")}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ color: "#000" }}>{t(alertTitle)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t(alertMessage)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showCancel && (
            <AmpButton
              type="secondary"
              onClick={() => handleClose("cancel")}
              text={cancelButton}
            />
          )}
          <AmpButton
            onClick={() => handleClose("confirm")}
            text={confirmButton}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
