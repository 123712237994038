import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
dayjs.extend(utcPlugin);

import {
  EvidenceStatus,
  RouteOptions,
  SortableFields,
} from "../lib/util/enums";
import { getNDaysAgo } from "../lib/util/dateFormatters";

// Evidence filters: optional properties
// Used to check if optional filters are active or not
// And for the Clear Filters button
export class ClearedEvidenceFilters {
  countries: undefined;
  issueIds: undefined;
  themeIds: undefined;
  languages: undefined;
  platforms: undefined;
  interactions: undefined;
  attachments: undefined;
}

// Construct evidence filter and search params
export class EvidenceFilters {
  view: RouteOptions;
  status?: EvidenceStatus[];
  sortField: SortableFields;
  sortDesc: boolean;
  dateRange: {
    gte: dayjs.Dayjs; // MinDate
    lte: dayjs.Dayjs; // MaxDate
  };
  searchText?: string;
  countries?: string[];
  issueIds?: string[];
  themeIds?: string[];
  languages?: string[];
  platforms?: string[];
  interactions?: number;
  attachments?: boolean;
  

  // Default search params:
  // Sort DESC (newest first) on date (if QA, on createdAt)
  // Date filter: Last 30 days
  constructor(view: RouteOptions, theme?: string, days?: number) {
    this.view = view;
    this.sortField =
      view === RouteOptions.QA ? SortableFields.CreatedAt : SortableFields.Date;
    // Leave status undefined unless in QA View
    this.status =
      view === RouteOptions.QA ? [EvidenceStatus.NeedsReview] : undefined;
    this.sortDesc = true;
    this.dateRange = {
      gte: getNDaysAgo(days),
      lte: dayjs().utc().endOf("day"),
    };
    if (theme) {
      this.themeIds = [theme];
    }
  }
}
