// Global
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI 
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";

// Import local
import AlertDialogSlide from "../AlertSlideDialog";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  target: RouteOptions;
}

const MobileX = ({ target }: Props) => {
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const navigate = useNavigate();

  // Confirm dialogue
  const handleUserChoice = (userChoice: string) => {
    if (userChoice === "confirm") navigate(target);
    setShowCancelDialog(false);
  };

  return (
    <Box>
      {/* Button */}
      <ClearIcon onClick={() => setShowCancelDialog(true)} />
      {/* Confirm dialogue */}
      <AlertDialogSlide
            title={"cancelInputTitle"}
            message={"cancelInputMessage"}
            isOpen={showCancelDialog}
            showCancel
            handleUserChoice={handleUserChoice}
          />
    </Box>
  );
};

export default MobileX;
