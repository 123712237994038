import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

// Local
import { labeledObject } from "../lib/util/interfaces";
import { themesQuery, issuesQuery } from "../lib/api/keyConstants";
import useAxios from "./useAxios";
import { AxiosResponse } from "axios";

interface QueryProps {
  queryKey: [string, { apiToken: string }];
}

// getThemesIssues business logic
const getThemesIssues = async ({ queryKey }: QueryProps) => {
  const [_key, { apiToken }] = queryKey;
  return await useAxios({
    token: apiToken,
    endpoint: _key === themesQuery ? "getThemes" : "getIssues",
  });
};

interface ParseProps {
  data: AxiosResponse;
  userLanguage: string;
}

// Parse results from API into a neat list
const parseResults = ({ data, userLanguage }: ParseProps) => {
  const dataList: labeledObject[] = [];
  const hits = data.data.response.body.hits.hits;
  for (const hit of hits) {
    // TODO: select from correct user lang preference
    dataList.push({
      id: hit._id,
      // Seek Theme and Issue name in userLang, otherwise default to En
      label:
        hit._source?.["nameTranslated." + userLanguage] ||
        hit._source["nameTranslated.en"],
      description:
        hit._source?.["descriptionTranslated." + userLanguage] ||
        hit._source["descriptionTranslated.en"],
      group: hit._source?.group,
    });
  }
  return dataList;
};

// State management for Themes, Issues
const useThemesIssues = (apiToken: string) => {
  const { i18n } = useTranslation();
  const userLanguage = i18n.language;

  // Get themes from Opensearch
  const themes = useQuery({
    queryKey: [themesQuery, { apiToken }],
    queryFn: getThemesIssues,
    // Pass the Axios data along with userLanguage to parsing
    select: (data) => parseResults({ data, userLanguage }),
    enabled: !!apiToken,
  });

  // Get issues from Opensearch
  const issues = useQuery({
    queryKey: [issuesQuery, { apiToken }],
    queryFn: getThemesIssues,
    // Pass the Axios data along with userLanguage to parsing
    select: (data) => parseResults({ data, userLanguage }),
    enabled: !!apiToken,
  });

  // API Success: Return data
  return { themes: themes.data || [], issues: issues.data || [] };
};

export default useThemesIssues;
