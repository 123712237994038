// Global
import { useState } from "react";

// Locals
import { EvidenceFilters } from "../models/EvidenceFilters";
import { RouteOptions } from "../lib/util/enums";
import { setEmptyArraysToUndefined } from "../lib/util/dataCleaning";
import { ClearedEvidenceFilters } from "../models/EvidenceFilters";
import { getNDaysAgo } from "../lib/util/dateFormatters";

interface Props {
  view: RouteOptions;
  theme?: string;
  days?: number;
}

// This hook manages the state for filters and search terms
// View parameter tells DB if this is a Collection or Analyze query
const useEvidenceFilters = ({ view, theme, days }: Props) => {
  const [evidenceFilters, setEvidenceFilters] = useState<EvidenceFilters>(
    new EvidenceFilters(view, theme, days)
  );

  // Keep function in sync with current view and trendingPeriod
  if (
    view !== evidenceFilters.view ||
    (days && !getNDaysAgo(days).isSame(evidenceFilters.dateRange.gte))
  ) {
    setEvidenceFilters(new EvidenceFilters(view, theme, days));
  }

  // This function cleans and updates filters
  const updateEvidenceFilters = (values: object) => {
    window.history.replaceState({}, document.title, window.location.pathname);
    setEmptyArraysToUndefined(values);
    setEvidenceFilters({ ...evidenceFilters, ...values });
    console.log("evidenceFilters: ", { ...evidenceFilters, ...values });
  };

  // Clear all optional filters in filters panel only
  // i.e., does not clear date or search text
  const clearEvidenceFilters = () => {
    updateEvidenceFilters(new ClearedEvidenceFilters());
  };

  return { evidenceFilters, clearEvidenceFilters, updateEvidenceFilters };
};

export default useEvidenceFilters;
