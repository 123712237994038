import { PresignedUrlTypes } from "../util/enums";
import useAxios from "../../hooks/useAxios";

interface QueryProps {
  token: string;
  key: string;
  type?: PresignedUrlTypes;
}

// Build createPresignedUrl API call
const createPresignedUrl = async ({ token, key, type }: QueryProps) => {
  const response = await useAxios({
    token: token,
    endpoint: "createPresignedUrl",
    params: { objectKey: key, attachmentType: type || PresignedUrlTypes.Evidence },
  });
  return response;
};

export default createPresignedUrl;
