// Global
import { useContext } from "react";
import { useTranslation } from "react-i18next";

// Import MUI
import { Box, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Import Local
import { CountryDataProps, countryArray } from "../../models/Countries";
import RequiredIcon from "./RequiredIcon";
import { IssuesContext, ThemesContext } from "../../contexts/InitContexts";
import { sortThemeByGroup, sortByLabel } from "../../lib/util/alphabetize";
import { COLORS } from "../../lib/theme/default";
import { africanLanguages } from "../../models/Languages";
import { labeledObject } from "../../lib/util/interfaces";
import { Platforms } from "../../models/Platform";
import { Theme } from "../../models/Theme";

interface Props {
  field: string;
  selectedValues: string[] | undefined | null;
  required?: boolean;
  isFilter?: boolean;
  updateFormData: (values: Record<string, string[]>) => void;
}

// Build a text field component
const AutocompleteMultiple = ({
  field,
  selectedValues,
  required,
  isFilter,
  updateFormData,
}: Props) => {
  const { t } = useTranslation();

  const themes = useContext(ThemesContext);
  const issues = useContext(IssuesContext);

  // Get dataset for this component based on field prop
  const getData = () => {
    switch (field) {
      case "issueIds":
        return sortByLabel(issues);
      case "themeIds":
        // Sort themes by new Theme array
        const data = sortByLabel(themes);
        const themeList = data.map(
          (item) => new Theme(item.id, item.label, item.group, item.description)
        );
        const groupedThemes = sortThemeByGroup(themeList);
        return groupedThemes;

      case "languages":
        return africanLanguages;
      case "platforms":
        // Parse platform object into labeledObject format
        let platforms: labeledObject[] = [];
        for (const platform in Platforms) {
          platforms.push({
            id: platform,
            label: Platforms[platform as keyof typeof Platforms].name,
          });
        }
        return platforms;
      default:
        return countryArray;
    }
  };

  // Set current state of values from props (selectedValues)
  const getValues = () => {
    if (selectedValues) {
      const currentData = [];
      const myOptions = getData();
      for (const value of selectedValues) {
        const index = myOptions.findIndex(
          (obj: labeledObject | CountryDataProps | Theme) => obj.id === value
        );
        if (index !== -1) currentData.push(myOptions[index]);
      }
      return currentData;
    } else return [];
  };

  // Push changes to formData
  const handleChange = (event: object, value: labeledObject[]) => {
    const data: Record<string, string[]> = {};
    data[field] = [];
    for (const row of value) data[field].push(row.id);
    updateFormData(data);
  };

  // Implement grouping, if required by themeIds
  const handleGrouping = (
    option: Theme | labeledObject | CountryDataProps
  ): string => {
    if (option instanceof Theme) {
      return option.group;
    } else {
      return "";
    }
  };

  // Set form label: Forms (Add Rumors) or Filters
  const labelType = isFilter ? "drawers.filters." : "forms.";

  return (
    <Box marginTop={2}>
      <Typography variant="h3" mb={0.5}>
        {t(labelType + field + "Label")}
        {required && <RequiredIcon />}
      </Typography>
      <Autocomplete
        multiple
        id={field + "Autocomplete"}
        options={getData()}
        groupBy={(option) => handleGrouping(option)}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={getValues()}
        filterSelectedOptions
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ backgroundColor: isFilter ? COLORS.backgroundGray : "auto" }}
          />
        )}
      />
    </Box>
  );
};

export default AutocompleteMultiple;
