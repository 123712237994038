import { Box, Stack } from "@mui/material";
import ChooseView from "../components/navigation/ChooseView";
import TrendingRumors from "../components/landingPage/TrendingRumors";
import { appMaxWidth, appMobileWidth } from "../lib/theme/default";
import Footer from "../components/landingPage/Footer";

// This view shows the post-auth landing page
const LandingPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ height: "calc(100vh - 84px)" }}
    >
      <Stack
        pb={2}
        alignItems={"stretch"}
        width={appMobileWidth}
        maxWidth={appMaxWidth}
        justifyContent={"space-between"}
      >
        <Stack spacing={2}>
          {/* CTAs for main workflows */}
          <ChooseView
            header="Contribute"
            subheader="SubmitRumors"
            buttonText="AddRumors"
          />

          <ChooseView
            header="Discover"
            subheader="Explore"
            buttonText="Discover"
          />

          {/* Show hot rumors */}
          <TrendingRumors />
        </Stack>
        <Footer />
      </Stack>
    </Box>
  );
};

export default LandingPage;
