// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Tooltip } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// Local
import AmpDrawer from "../drawers/AmpDrawer";
import useAmpDrawer from "../../hooks/useAmpDrawer";
import { AmpDrawerState } from "../../lib/util/enums";
import FiltersDrawer from "../drawers/evidence/FiltersDrawer";
import { EvidenceFiltersProps } from "../../lib/util/interfaces";
import { hasAllUndefinedProperties } from "../../lib/util/dataCleaning";

const FilterButton = ({
  evidenceFilters,
  isQA,
  updateEvidenceFilters,
  clearEvidenceFilters,
}: EvidenceFiltersProps) => {
  // Global
  const { t } = useTranslation();

  // Local
  const { drawerState, setDrawerState } = useAmpDrawer();
  const isClear = hasAllUndefinedProperties(evidenceFilters);

  return (
    <>
      <Tooltip title={t("subNav.tooltip.FilterButton")}>
        <Box
          border={0}
          display="flex"
          alignItems={"center"}
          height={36}
          onClick={() => setDrawerState(AmpDrawerState.Filters)}
        >
          {!isClear && (
            <FilterAltIcon sx={{ fontSize: "2rem" }} color={"secondary"} />
          )}
          {isClear && (
            <FilterAltOutlinedIcon
              sx={{ fontSize: "2rem" }}
              color={"primary"}
            />
          )}
        </Box>
      </Tooltip>

      {/* Toggle components */}
      {!!drawerState && (
        <AmpDrawer
          drawerState={drawerState}
          handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
        >
          <FiltersDrawer
            evidenceFilters={evidenceFilters}
            isQA={isQA}
            updateEvidenceFilters={updateEvidenceFilters}
            clearEvidenceFilters={clearEvidenceFilters}
          />
        </AmpDrawer>
      )}
    </>
  );
};

export default FilterButton;
