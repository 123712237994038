// Global
import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

// Local
import { ApiTokenContext } from "../contexts/InitContexts";
import useAxios from "./useAxios";
import {
  Evidence,
  EvidenceAggregatedByDay,
  EvidenceAggregatedByLocation,
} from "../models/Evidence";
import { evidence } from "../lib/api/keyConstants";
import { EvidenceFilters } from "../models/EvidenceFilters";
import { cardsPerPage } from "../lib/configs";
import {
  cleanDuplicatesFromObjectArrays,
  searchTermCleaning,
} from "../lib/util/dataCleaning";
import { RouteOptions } from "../lib/util/enums";

interface QueryProps {
  queryKey: [
    string,
    {
      token: string;
      evidenceFilters: EvidenceFilters;
      isAggregationsOnly?: boolean;
    }
  ];
  pageParam?: number | undefined;
}

// getEvidence business logic
const getEvidence = async ({ queryKey, pageParam = 0 }: QueryProps) => {
  const [_key, { token, evidenceFilters, isAggregationsOnly }] = queryKey;

  // Parse evidenceFilters to build /getEvidence endpoint params
  let params: any = {
    page: pageParam || 0,
    pageSize: isAggregationsOnly ? 0 : cardsPerPage,
    sortField: evidenceFilters.sortField,
    sortOrder: evidenceFilters.sortDesc === true ? "desc" : "asc",

    startDate: evidenceFilters.dateRange.gte.valueOf(),
    endDate: evidenceFilters.dateRange.lte.valueOf(),
    searchText: evidenceFilters?.searchText
      ? searchTermCleaning(evidenceFilters?.searchText)
      : undefined,
    status: JSON.stringify(evidenceFilters.status),
    countries: JSON.stringify(evidenceFilters.countries),
    issueIds: JSON.stringify(evidenceFilters.issueIds),
    themeIds: JSON.stringify(evidenceFilters.themeIds),
    languages: JSON.stringify(evidenceFilters.languages),
    platforms: JSON.stringify(evidenceFilters.platforms),
    // Hide other authors as proxy for "My Collection"
    hideOtherAuthors: evidenceFilters.view === RouteOptions.collection,
    // Hide Aggregations on Collection and QA view
    hideAggregations:
      evidenceFilters.view === RouteOptions.collection ||
      evidenceFilters.view === RouteOptions.QA,
  };

  const response = await useAxios({
    token: token,
    endpoint: "getEvidence",
    params: params,
  });

  return response;
};

interface ParseProps {
  data: { pages: AxiosResponse[] };
}

// Parsing successfully fetch results:
// Process hits and evidence into structured data elements
const parseResults = ({ data }: ParseProps) => {
  const evidenceList: Evidence[] = [];

  // Loop through "pages" of data (Infinite Query array)
  for (const page of data.pages) {
    const hits = page.data.response.body.hits.hits;

    for (const hit of hits) {
      const evidenceCard = new Evidence(hit);
      // Remove duplicates in arrays (e.g., themes, issues)
      cleanDuplicatesFromObjectArrays(evidenceCard);
      evidenceList.push(evidenceCard);
    }
  }
  // Get evidenceByDate aggregation and create object
  if (data.pages[0].data.response.body.aggregations) {
    const evidenceByDate =
      data.pages[0].data.response.body.aggregations.evidenceByDate.buckets;
    const lineChartData: EvidenceAggregatedByDay[] = evidenceByDate.map(
      (day: any) => new EvidenceAggregatedByDay(day)
    );

    // Get evidenceByLocation aggregation and create object
    const evidenceByLocation: EvidenceAggregatedByLocation[] =
      data.pages[0].data.response.body.aggregations.evidenceByLocation.buckets;
    const mapData: EvidenceAggregatedByLocation[] = evidenceByLocation.map(
      (location: any) => new EvidenceAggregatedByLocation(location)
    );

    // Return with aggregations
    return {
      evidenceList,
      chartData: { lineChartData: lineChartData, mapData: mapData },
    };
  }

  // Return w/o aggergations (for My Collection)
  return { evidenceList };
};

interface Props {
  evidenceFilters: EvidenceFilters;
  isQA?: boolean;
  isAggregationsOnly?: boolean;
}

// State management for Evidence
// Infinite scroll UX
export const useEvidence = ({ evidenceFilters, isAggregationsOnly }: Props) => {
  const token = useContext(ApiTokenContext);

  const {
    isLoading,
    isFetching,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [evidence, { token, evidenceFilters, isAggregationsOnly }],
    queryFn: getEvidence,
    // SELECT is commented out due to Typing error
    // select: (result) => parseResults(result),
    // Calculate pageParam or returned undefined to disable LoadMore
    getNextPageParam: (lastPage, pages) => {
      const isMoreData =
        lastPage.data.response.body.hits.total.value >
        pages.length * cardsPerPage;
      return isMoreData ? pages.length : undefined;
    },
    enabled: !!token,
  });

  // Show user a spinner or handle error
  if (isLoading || isFetching) {
    // if (!evidenceFilters.active) {
    //   return { resultCount: 0 };
    // }
    return { isLoading };
  }

  if (error instanceof Error) {
    // TODO: Update this to handle missing evidence gracefully
    return { error };
  }

  if (data) {
    const parsedResults = parseResults({ data });
    const resultCount: number | undefined =
      data?.pages[0].data.response.body.hits.total.value.toString();

    // Return parsed data
    return {
      evidenceList: parsedResults.evidenceList,
      resultCount: resultCount,
      chartData: parsedResults.chartData,
      manageNextPage: { hasNextPage, isFetchingNextPage, fetchNextPage },
    };
  }
  return {};
};

export default useEvidence;
