// Global
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// MUI
import { LicenseInfo } from "@mui/x-license-pro";

// Locals
import "./index.css";
import "./i18n";
import theme from "./lib/theme/default";
import { ThemeProvider } from "@mui/material/styles";
import CircleLoading from "./components/navigation/CircleLoading";

// Views
import App from "./App";
import ErrorPage from "./views/ErrorPage";
import AddEvidence from "./views/AddEvidence";
import LandingPage from "./views/LandingPage";
import MyCollection from "./views/MyCollection";
import ContentManager from "./views/ContentManager";
import Analyze from "./views/Analyze";
import DiscoverRumors from "./views/DiscoverRumors";

// MUI X license
LicenseInfo.setLicenseKey(
  "d59f36d7cf70101a259bb4bb1be2e5faTz02NzY1MSxFPTE3MTcxNzA3ODY2MjYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// Tanstack React Query init
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// React Router route management
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: "submit",
        element: <AddEvidence />,
      },
      {
        path: "collection",
        element: <MyCollection />,
      },
      {
        path: "discover",
        element: <DiscoverRumors />,
      },
      {
        path: "analyze",
        element: <Analyze />,
      },
      {
        path: "content",
        element: <ContentManager />,
      },
      {
        path: "qa",
        element: <MyCollection isQA={true} />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Auth0Provider
    domain="africamisinformationportal.us.auth0.com"
    clientId="sLTfol1UAMctJPWZvkysw5xvEVX4g8yP"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://africamisinformationportal.us.auth0.com/api/v2/",
    }}
    cacheLocation="localstorage"
  >
    <React.StrictMode>
      <Suspense fallback={<CircleLoading />}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </ThemeProvider>
        </QueryClientProvider>
      </Suspense>
    </React.StrictMode>
  </Auth0Provider>
);
