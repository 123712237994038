import { useState } from "react";

// Handles management of wizard "pages" (steps) for AddEvidence
const useStepHandler = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [allowNext, setAllowNext] = useState<boolean>(false);

  // Handle user actions to step (f)orward or (b)ack
  // Also submits data or resets form to submit again
  // Param can also be a number, which sets currentStep as needed
  const changeStep = async (param: string | number) => {
    if (typeof param === "number") {
      setCurrentStep(param);
    }
    if (param === "f") {
      setCurrentStep(currentStep + 1);
    } else if (param === "b" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Values and functions to expose
  const stepHandler = {
    currentStep: currentStep,
    changeStep: changeStep,
    isSubmitting: isSubmitting,
    setIsSubmitting: setIsSubmitting,
    allowNext: allowNext,
    setAllowNext: setAllowNext,
  };
  return { stepHandler };
};

export default useStepHandler;
