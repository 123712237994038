// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography } from "@mui/material";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent={"center"} mt="auto" textAlign="center">
      <Typography variant="body1" fontWeight={400}>
        {t("footer")}
      </Typography>
    </Box>
  );
};

export default Footer;
