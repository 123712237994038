// Global
import { useNavigate } from "react-router-dom";

// Import MUI
import { Box, Stack } from "@mui/material";
import AmpButton from "../buttons/AmpButton";

// Import local
import ShowBadge from "./ShowBadge";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  badgeCount?: number;
  submitAgain: (direction: string) => void;
}

const AddEvidenceSubmitted = ({ badgeCount, submitAgain }: Props) => {
  const navigate = useNavigate();

  const nextStep = (choice: string) => {
    // TODO: Add in redirects to Discover, View Collection
    if (choice === "submitAgain") {
      submitAgain("submitAgain");
    }
  };

  // If submitEvidence is a success:
  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <ShowBadge badgeCount={badgeCount} />

      <Stack spacing={2} width={300} marginTop={4}>
        <AmpButton
          text="SubmitAgain"
          // Simplify this
          onClick={() => nextStep("submitAgain")}
        />
        <AmpButton
          text="Discover"
          type="secondary"
          onClick={() => navigate(RouteOptions.discover)}
        />
        <AmpButton
          text="ViewCollection"
          type="secondary"
          onClick={() => navigate(RouteOptions.collection)}
        />
      </Stack>
    </Box>
  );
};

export default AddEvidenceSubmitted;
