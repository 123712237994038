// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Typography } from "@mui/material";

// Local
import { Evidence } from "../../../models/Evidence";
import EvidenceCard from "../../cards/EvidenceCard";
import { smallFont } from "../../../lib/theme/default";
import { idLabelLookup } from "../../../lib/util/idLookups";
import { africanLanguages } from "../../../models/Languages";

interface Props {
  evidence: Evidence;
  isQA?: boolean;
}

interface KeyValue {
  key: string;
  value: any;
}

// Static list of details to include in function
const otherDetailsOptions = ({ evidence, isQA }: Props): any[] => {
  const options = [
    evidence.id,
    evidence.createdAt,
    evidence.updatedAt,
    evidence?.url,
    evidence?.detectedLanguage,
    evidence?.submittedLanguage,
    evidence?.mediaName,
    evidence?.socialMediaPostType,
    evidence?.originalPost,
    evidence?.likeCount,
    evidence?.shareCount,
    evidence?.viewCount,
    evidence?.commentCount,
    evidence?.retweetCount,
    evidence?.notes,
  ];

  if (isQA) {
    options.push(evidence?.authorId);
    options.push(evidence?.status);
  }

  return options;
};

// Show additional details about Evidence for the "See Details" drawer
// This function sits directly below the evidence card info in the drawer
// So the properties shown are manually curated to be additive (not duplicative)
// To data already found in the Evidence Card
const EvidenceDetails = ({ evidence, isQA }: Props) => {
  const { t } = useTranslation();
  // Create a list of other details
  const otherDetails = () => {
    const otherList: KeyValue[] = [];
    for (const [k, v] of Object.entries(evidence)) {
      if (
        !!v &&
        otherDetailsOptions({ evidence, isQA }).some((item) => item === v)
      ) {
        // Look up the full name of the language
        let x: string = "";
        if (k === "detectedLanguage" || k === "submittedLanguage") {
          x = idLabelLookup({ id: v, data: africanLanguages });
        }
        otherList.push({ key: k, value: x || v });
      }
    }
    const mappedList = otherList.map((obj, index) => (
      <Box mt={1} key={index}>
        <Typography variant="h4" display="inline" sx={{ fontSize: smallFont }}>
          {obj.key.toString()}:
        </Typography>{" "}
        <Typography variant="body1" display="inline" pr={1}>
          {obj.value.toString()}
        </Typography>
      </Box>
    ));
    return mappedList;
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >
      <Typography variant="h1">{t("drawers.details.Header")}</Typography>
      <EvidenceCard evidence={evidence} isDetailView={true} />
      <hr />
      <>{otherDetails()}</>
    </Stack>
  );
};

export default EvidenceDetails;
