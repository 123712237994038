// Local
import { EvidenceStatus, SocialMediaPostType } from "../lib/util/enums";
import { DataIntensity } from "../lib/util/enums";
import { locationLookup } from "../lib/util/idLookups";
import { CountryDataProps } from "./Countries";

export class Evidence {
  readonly id: string;
  readonly status: EvidenceStatus;
  readonly attachments?: string[];
  readonly authorId?: string;
  readonly commentCount?: number;
  readonly countries?: string[];
  readonly createdAt: Date;
  readonly date: Date;
  readonly detectedLanguage?: string;
  readonly interactionCount?: number;
  readonly issueIds?: string[];
  readonly likeCount?: number;
  readonly mediaName?: string;
  readonly notes?: string;
  readonly originalPost?: string;
  readonly platform?: string;
  readonly retweetCount?: number;
  readonly shareCount?: number;
  readonly socialMediaPostType?: SocialMediaPostType;
  readonly submittedLanguage?: string;
  readonly text?: string;
  readonly textTranslated?: Record<string, string>;
  readonly themeIds?: string[];
  readonly updatedAt: Date;
  readonly url?: string;
  readonly viewCount?: number;

  constructor(data: any) {
    const source = data._source;
    this.id = data._id;
    this.status = source.hasOwnProperty("status")
      ? source.status
      : EvidenceStatus.Active;
    this.createdAt = new Date(source.createdAt);
    this.date = new Date(source.date);
    this.updatedAt = new Date(source.updatedAt);

    if (source) {
      this.attachments = source.attachments;
      this.authorId = source.authorId;
      this.commentCount = source.commentCount;
      this.countries = source.countries;
      this.detectedLanguage = source.detectedLanguage;
      this.issueIds = source.issueIds;
      this.likeCount = source.likeCount;
      this.mediaName = source.mediaName;
      this.notes = source.notes;
      this.originalPost = source.originalPost;
      this.platform = source.platform;
      this.retweetCount = source.retweetCount;
      this.shareCount = source.shareCount;
      this.socialMediaPostType = source.socialMediaPostType;
      this.submittedLanguage = source.submittedLanguage;
      this.text = source.text;
      this.textTranslated = source.textTranslated;
      this.themeIds = source.themeIds;
      this.url = source.url;
      this.viewCount = source.viewCount;

      // Build interactionCount
      if (source.interactionCount) {
        this.interactionCount = source.interactionCount;
      } else if (
        source.likeCount ||
        source.commentCount ||
        source.shareCount ||
        source.retweetCount
      ) {
        this.interactionCount =
          (Number(source.likeCount) || 0) +
          (Number(source.commentCount) || 0) +
          (Number(source.shareCount) || 0) +
          (Number(source.retweetCount) || 0);
      }
    }
  }
}

// A structured Evidence object for charts from Opensearch data
export class EvidenceAggregatedByDay {
  count: number;
  date: Date;

  constructor(data: any) {
    this.count = data.doc_count;
    this.date = new Date(data.key);
  }
}

// A structured Evidence object for maps from Opensearch data
export class EvidenceAggregatedByLocation {
  count: number;
  location: CountryDataProps;
  intensity?: DataIntensity;

  constructor(data: any) {
    this.count = data.doc_count;
    this.location = locationLookup(data.key);
  }
}
