// Global
import { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";

// Local
import useAxios from "./useAxios";
import { ApiTokenContext } from "../contexts/InitContexts";
import { scrapeEvidenceQuery } from "../lib/api/keyConstants";

interface QueryProps {
  queryKey: [string, { token: string; url: string; autoSubmit?: boolean }];
}

// Business logic to get back Social Media metadata from the provided URL
const scrapeEvidence = async ({ queryKey }: QueryProps) => {
  const [_key, { token, url, autoSubmit }] = queryKey;

  // Add params. Delete autosubmit if false
  const params = { url: url, autoSubmit: autoSubmit };
  if (!autoSubmit) {
    delete params.autoSubmit;
  }

  // Call endpoint, await results
  return await useAxios({
    token: token,
    endpoint: "/scrapeEvidence",
    params: params,
  });
};

// This hook manages two QuickAdd functions:
// QuickAdd: Automatically index a new evidence doc in opensearch
// QuickAddReview: Auto-populate formData with evidence metadata
const useQuickAdd = () => {
  const token = useContext(ApiTokenContext);
  const [url, setUrl] = useState<string>("");
  const [autoSubmit, setAutoSubmit] = useState<boolean>(false);

  // Call useQuickAdd from component
  const handleUseQuickAdd = (url: string | undefined, autoSubmit?: boolean) => {
    if (url) {
      if (autoSubmit) {
        setAutoSubmit(true);
      }
      setUrl(url);
    }
  };

  // Ping /scrapeEvidence endpoint
  const { error, data } = useQuery({
    queryKey: [scrapeEvidenceQuery, { token, url, autoSubmit }],
    queryFn: scrapeEvidence,
    enabled: !!token && !!url,
  });

  // Graceful error handle: Require manual user input
  if (error || (!!data?.status && data.status !== 200)) {
    return { quickAddError: true, handleUseQuickAdd };
  }

  if (data?.status) {
    if (data.status === 200 && autoSubmit) {
      // Return success to parent, skip to Step 5
      return { quickAddCallback: 200, handleUseQuickAdd };
    } else if (data.status === 200 && !autoSubmit) {
      // Return success to parent, prepopulate form and go to step 2
      return {
        quickAddData: data.data.response,
        handleUseQuickAdd,
      };
    }
  }
  return { handleUseQuickAdd };
};

export default useQuickAdd;
