// Global
import { Link, useRouteError } from "react-router-dom";

// MUI
import { Box, Typography } from "@mui/material";

// Local
import { COLORS, appMaxWidth, appMobileWidth } from "../lib/theme/default";
import AmpLogo from "../components/landingPage/AmpLogo";
import { RouteOptions } from "../lib/util/enums";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Box
    className='App'  
    sx={{
        backgroundColor: COLORS.white,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        width={1}
        sx={{
          maxWidth: appMaxWidth,
          width: appMobileWidth,
        }}
      > <AmpLogo />
        <Typography variant="h1" mt={2}>Oops! An error occurred.</Typography>
        <Typography variant="h4" mt={2}>Error: {error.statusText || error.message}</Typography>
        <Typography variant="h3" mt={2}>Click your browser's back button or <Link to={RouteOptions.landing}>click here</Link> to return home.</Typography>
        <Typography variant="h3" mt={2}>If the problem persists, contact <a href="mailto:amp@rootwise.co">amp@rootwise.co</a> for support.</Typography>
      </Box>
    </Box>
  );
}
