// Global
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

// MUI and Formik
import { Box } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Local
import TextFieldInput from "./TextFieldInput";
import { InputStepProps } from "../../lib/addEvidenceForms/props";
import { urlSchema } from "../../lib/util/validators";

// Component to auto-fill data based on available web data
const QuickAdd = ({
  handleAllowNext,
  updateFormData,
  formData,
}: InputStepProps) => {
  const { t } = useTranslation();

  // Hook to manage QuickAdd values and actions
  useEffect(() => {
    if (formData.url) {
      handleAllowNext(true);
    } else {
      handleAllowNext(false);
    }
  }, [formData.url, handleAllowNext]);

  return (
    <Box>
      <Formik
        initialValues={{
          url: formData.url ? formData.url : "",
        }}
        validationSchema={Yup.object({
          url: urlSchema
            .matches(
              /^(https?:\/\/)?(?:www\.)?tiktok\.com\/.*$/,
              "Please enter a valid TikTok URL"
            ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateFormData(values);
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <TextFieldInput field="url" />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default QuickAdd;
