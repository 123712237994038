// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Local
import SubNav from "../components/navigation/SubNav";
import { RouteOptions } from "../lib/util/enums";
import AmpCard, { AmpCardTypes } from "../components/cards/AmpCard";
import {
  appMaxWidth,
  appMobileWidth,
  cardMaxWidth,
} from "../lib/theme/default";
import { useEvidence } from "../hooks/useEvidence";
import CircleLoading from "../components/navigation/CircleLoading";
import { Evidence } from "../models/Evidence";
import EvidenceCard from "../components/cards/EvidenceCard";
import useEvidenceFilters from "../hooks/useEvidenceFilters";
import LineChart from "../components/visualizations/LineChart";
import LoadMoreButton from "../components/buttons/LoadMoreButton";
import EvidenceMap from "../components/visualizations/EvidenceMap";
import DisplayResultCount from "../components/cards/DisplayResultCount";
import EvidenceFilterControls from "../components/filters/EvidenceFilterControls";

// User view with filters, evidence cards and charts
const Analyze = () => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Pre-filtered parameters
  const queryParameters = new URLSearchParams(window.location.search);
  const themeId = queryParameters.get("themeId");
  const period = queryParameters.get("period")
    ? Number(queryParameters.get("period")) - 1
    : undefined;

  

  // Local hooks
  const { evidenceFilters, updateEvidenceFilters, clearEvidenceFilters } =
    useEvidenceFilters({
      view: RouteOptions.analyze,
      theme: themeId || undefined,
      days: period || undefined,
    });

  const {
    evidenceList,
    resultCount,
    chartData,
    error,
    manageNextPage,
    isLoading,
  } = useEvidence({ evidenceFilters });

  // Reference for line chart visualization
  // Undefined if no data
  const displayLineChart =
    chartData?.lineChartData && chartData.lineChartData.length > 0 ? (
      <AmpCard type={AmpCardTypes.chart}>
        <LineChart
          lineChartData={chartData.lineChartData}
          evidenceFilters={evidenceFilters}
        />
      </AmpCard>
    ) : undefined;

  // Reference for map visualization
  // Undefined if no data
  const displayMap =
    chartData?.mapData && chartData.mapData.length > 0 ? (
      <AmpCard type={AmpCardTypes.chart}>
        <EvidenceMap data={chartData.mapData} />
      </AmpCard>
    ) : undefined;

  return (
    <Box>
      {/* SubNav Container */}
      <SubNav view={RouteOptions.analyze}>
        <EvidenceFilterControls
          view={RouteOptions.analyze}
          evidenceFilters={evidenceFilters}
          updateEvidenceFilters={updateEvidenceFilters}
          clearEvidenceFilters={clearEvidenceFilters}
        />
      </SubNav>

      <Box display="flex" justifyContent={"center"}>
        <Box
          minWidth={isSmallScreen ? appMobileWidth : cardMaxWidth}
          maxWidth={isSmallScreen ? appMobileWidth : appMaxWidth}
          justifyContent={"center"}
          mt={1}
        >
          {/* Display loading state */}
          {isLoading && <CircleLoading />}
          {!!error && error.toString()}

          {/* Display number of results to user  */}
          <DisplayResultCount count={resultCount} />

          <Grid container columnGap={2}>
            {/* Mobile: charts at top */}
            {isSmallScreen && chartData && (
              <Grid xs={12}>
                {displayLineChart}
                {displayMap}
              </Grid>
            )}

            {/* Evidence cards */}
            <Grid xs={12} md>
              {evidenceList &&
                // Map individual evidence cards
                evidenceList.map((evidence: Evidence) => (
                  <AmpCard type={AmpCardTypes.evidence} key={evidence.id}>
                    <EvidenceCard evidence={evidence} />
                  </AmpCard>
                ))}

              {/* Load more button */}
              {!isLoading &&
                evidenceList &&
                evidenceList?.length > 0 &&
                manageNextPage && (
                  <LoadMoreButton manageNextPage={manageNextPage} />
                )}
            </Grid>
            {/* Desktop: Charts at right */}
            {!isSmallScreen && chartData && (
              <Grid md={"auto"}>
                {displayLineChart} {displayMap}
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Analyze;
