// MUI
import { Stack, useMediaQuery, useTheme } from "@mui/material";

// Local
import AmpButton from "./AmpButton";

interface Props {
  cancel: (event: any) => void;
  submit: (event: any) => void;
}

const CancelSubmitButtonPair = ({ cancel, submit }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Build cancel button UI
  const cancelButton = (
    <AmpButton
      text="Cancel"
      type="secondary"
      onClick={cancel}
    />
  );
  return (
    <Stack
      direction={isSmallScreen ? "column" : "row"}
      display="flex"
      justifyContent={"space-between"}
      spacing={1}
    >
      {!isSmallScreen && cancelButton}
      <AmpButton text="Submit" onClick={submit} />
      {isSmallScreen && cancelButton}
    </Stack>
  );
};

export default CancelSubmitButtonPair;
