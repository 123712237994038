// Global
import { useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";

// Local
import { CardTypeAndId } from "../lib/util/interfaces";
import { User } from "@auth0/auth0-react";
import { generateUniqueId } from "../lib/util/generators";
import { getUtcDateLocalized } from "../lib/util/dateFormatters";
import { ApiTokenContext } from "../contexts/InitContexts";
import submitData from "../lib/util/submitData";
import { AmpDataSubmitTypes } from "../lib/util/enums";

// Create a data object which holds metadata and user feedback
// This data is passed to /sendEmail endpoint on user Submit
export class FlagForReviewData {
  case: string;
  dataType: string;
  opensearchId: string;
  date: string;
  userUrl: string;
  description?: string;
  user?: string;

  constructor(type: string, id: string, userLang: string, user?: User) {
    (this.case = generateUniqueId(4)),
      (this.dataType = type),
      (this.opensearchId = id),
      (this.date = getUtcDateLocalized({
        date: new Date(),
        userLang: userLang,
      }).toString()),
      (this.userUrl = window.location.href),
      (this.user = user?.name);
  }
}

interface Props extends CardTypeAndId {
  userLang: string;
  user?: User;
}

const useFlagForReview = ({ type, id, userLang, user }: Props) => {
  // Local hooks and state
  const apiToken = useContext(ApiTokenContext);
  const mutation = useMutation(submitData);
  const [flagForReviewData, setFlagForReviewData] = useState(
    new FlagForReviewData(type, id, userLang, user)
  );

  // Update description with user text
  const setDescription = (text: string) => {
    setFlagForReviewData({ ...flagForReviewData, description: text });
  };

  // Submit and receive mutation response on user click
  const submitFlagForReview = () => {
    console.log("flagForReviewData", flagForReviewData);
    mutation.mutate({
      submitType: AmpDataSubmitTypes.FlagForReview,
      data: flagForReviewData,
      apiToken,
    });
  };

  return {
    flagForReviewData,
    setDescription,
    submitFlagForReview,
    status: mutation.status,
  };
};

export default useFlagForReview;
