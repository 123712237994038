// Global

// Local
import { idLookups } from "./idLookups";
import { labeledObject } from "./interfaces";

interface Props {
  ids: string[] | undefined;
  themeData: labeledObject[];
  isAlphabetized?: boolean;
}

// This function returns AMP theme data from given Ids and ThemeContext
const constructAmpThemes = ({ ids, themeData, isAlphabetized }: Props) => {
  if (!ids?.length) {
    return null;
  }

  // Get trending themes data from trendingRumorIds
  const themes = idLookups({
    data: themeData,
    ids: ids,
    isAlphabetized: isAlphabetized,
  });

  return themes;
};

export default constructAmpThemes;
