// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Tooltip } from "@mui/material";

// Local
import { CardTypeAndId } from "../../lib/util/interfaces";

// This component allows user to select or deselect My Collection items
// TODO: Make component controlled and functional
const SaveToCollection = ({ type, id }: CardTypeAndId) => {
  // Global hooks
  const { t } = useTranslation();

  // Local hooks and state
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleClick = () => {
    console.log("I clicked on:", type, id);
    setIsChecked(!isChecked);
  };

  return (
    <>
      {!isChecked && (
        <Tooltip title={t("cards.tooltip.SaveToCollection")}>
          <BookmarkBorderIcon color={"primary"} onClick={handleClick} />
        </Tooltip>
      )}
      {isChecked && (
        <Tooltip title={t("cards.tooltip.RemoveFromCollection")}>
          <BookmarkIcon color={"secondary"} onClick={handleClick} />
        </Tooltip>
      )}
    </>
  );
};

export default SaveToCollection;
