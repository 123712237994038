// Globals
import { useEffect, useState } from "react";

import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

// Local
import { AddEvidenceFormData } from "../../lib/addEvidenceForms/props";
import AttachedFile from "./AttachedFile";
import DragDropDesign from "./dragDropDesign";
import { FileTypes } from "../../models/FileTypes";
import { maxAttachmentSize } from "../../lib/configs";
import AlertDialogSlide from "../AlertSlideDialog";
import useCustomError from "../../hooks/useCustomError";

interface Props {
  formData: AddEvidenceFormData;
  isSubmitting: boolean;
  finishSubmit: (attachments: object) => void;
}

const AddAttachments = ({ formData, finishSubmit, isSubmitting }: Props) => {
  const [fileArray, setFileArray] = useState<File[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<string[]>([]);

  // Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { errorMessage, setErrorMessage } = useCustomError();

  // Get allowable file types
  const extValues = FileTypes.map((fileType) => fileType.ext);

  // Update filesArray when user adds attachment
  const handleChange = async (file: FileList) => {
    const fileListArray = Array.from(file);
    setFileArray([...fileArray, ...fileListArray]);
  };

  // Remove attachments when user clicks "X"
  const handleRemove = (index: number) => {
    const newArray = [...fileArray];
    newArray.splice(index - 1, 1);
    setFileArray(newArray);
  };

  // handle uploading files on submit
  const handleUploaded = (path: string) => {
    setFilesUploaded([...filesUploaded, path]);
  };

  // Monitor for submitting and uploaded complete
  useEffect(() => {
    if (isSubmitting && filesUploaded?.length === fileArray.length) {
      finishSubmit({
        // Check if attachments already added by quickAdd
        attachments: formData.attachments
          ? filesUploaded?.length > 0
            ? [...formData.attachments, ...filesUploaded]
            : [...formData.attachments]
          : filesUploaded,
      });
    }
  }, [isSubmitting, filesUploaded, fileArray]);

  // Text labels (prompt and innner instructions)
  const questionLabel = t("forms.DragDrop.Label");
  const innerLabel = t("forms.DragDrop.InnerLabel");

  return (
    <Box>
      <Typography variant="h3" mt={2}>
        {questionLabel}
      </Typography>
      {/* DragDrop zone */}
      <Box
        width={1}
        mt={1}
        display={isSmallScreen ? "auto" : "inline-flex"}
        alignItems={"start"}
      >
        <Box
          display={isSmallScreen ? "auto" : "inline-flex"}
          width={isSmallScreen ? 1 : "50%"}
          height={160}
        >
          {/* This component handles attaching files */}
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={extValues} // Allowable file extensions
            multiple={true} // Allow multiple files
            maxSize={maxAttachmentSize} // Max file size in MBs
            children={DragDropDesign(innerLabel)} // Component display style
            onTypeError={() => setErrorMessage("errors.FileType")}
            onSizeError={() => setErrorMessage("errors.FileSize")}
          />
        </Box>
        <Box ml={isSmallScreen ? 0 : 2} mt={isSmallScreen ? 2 : 0}>
          {/* Mapped reports of uploaded files */}
          {fileArray.length > 0 && (
            <Typography variant="h4">{t("forms.DragDrop.FileList")}</Typography>
          )}
          {fileArray.map((attachment, index) => (
            <Box key={index}>
              <AttachedFile
                attachment={attachment}
                uuid={formData.id}
                // Create attachment exemption for QuickAdd
                // Because QuickAdd has a built-in attachment
                attachmentIndex={index + 1}
                isSubmitting={isSubmitting}
                handleRemove={handleRemove}
                handleUploaded={handleUploaded}
              />
            </Box>
          ))}
          {/* Alert dialogue for errors: File Type or File Size */}
          <AlertDialogSlide
            title={errorMessage + ".title"}
            message={errorMessage + ".message"}
            confirm="Confirm"
            // Open on error message
            isOpen={!!errorMessage}
            // Reload window on dialogue close
            handleUserChoice={() => setErrorMessage("")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddAttachments;
