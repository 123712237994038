// Global
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useEditEvidence from "../../hooks/useEditEvidence";
import { Evidence } from "../../models/Evidence";
import { EvidenceStatus } from "../../lib/util/enums";

// Local

interface Props {
  evidence: Evidence;
}

const ReviewControl = ({ evidence }: Props) => {
  // Global
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local
  const [status, setStatus] = useState<EvidenceStatus>(evidence.status);

  const { submitEdits, isLoading } = useEditEvidence({
    evidenceId: evidence.id,
  });

  const handleStatus = (newValue: EvidenceStatus) => {
    // Submit change to Opensearch & reflect locally
    submitEdits("status", newValue);
    setStatus(newValue);
  };

  return isLoading ? (
    <Box
      mt={isSmallScreen ? 2 : 0}
      ml={isSmallScreen ? "auto" : 4}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      height="35px"
      width={isSmallScreen ? "auto" : "85px"}
    >
      {isLoading}
    </Box>
  ) : (
    <Stack
      mt={isSmallScreen ? 2 : 0}
      ml={isSmallScreen ? "auto" : 4}
      justifyContent={"center"}
      direction="row"
      spacing={2}
    >
      <Tooltip
        title={
          status === EvidenceStatus.Archived
            ? t("cards.tooltip.SetNotArchived")
            : t("cards.tooltip.SetArchived")
        }
      >
        <DeleteIcon
          color={status === EvidenceStatus.Archived ? "secondary" : "primary"}
          fontSize="large"
          onClick={() => handleStatus(EvidenceStatus.Archived)}
        />
      </Tooltip>
      <Tooltip
        title={
          status === EvidenceStatus.Reviewed
            ? t("cards.tooltip.SetNotReviewed")
            : t("cards.tooltip.SetReviewed")
        }
      >
        <CheckCircleIcon
          color={status === EvidenceStatus.Reviewed ? "secondary" : "primary"}
          fontSize="large"
          onClick={() => handleStatus(EvidenceStatus.Reviewed)}
        />
      </Tooltip>
    </Stack>
  );
};

export default ReviewControl;
