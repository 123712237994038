// Global
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import { Icon } from "leaflet";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography } from "@mui/material";

// Local
import {
  mapDefaultCoordinates,
  mapDefaultZoom,
  mapHeight,
  minimapDefaultZoom,
  minimapHeight,
} from "../../lib/theme/default";
import { EvidenceAggregatedByLocation } from "../../models/Evidence";
import calcMarkerIntensity from "../../lib/util/calcMarkerIntensity";
import { DataIntensity } from "../../lib/util/enums";

interface Props {
  data: EvidenceAggregatedByLocation[];
  isMinimap?: boolean;
}

// Display map of evidence on Analyze Tab
// Display minimap of evidence on Theme cards
const EvidenceMap = ({ data, isMinimap }: Props) => {
  // Global hooks
  const { t } = useTranslation();

  // Calculate high/low concentrations of data by location
  const dataWithIntensity = calcMarkerIntensity(data);
  // Icon maker: https://icons8.com/icons/set/marker

  // Customize markers
  // Set color by data intensity for location
  const customIcon = (intensity?: string) =>
    new Icon({
      iconUrl: `/icons/marker-${
        intensity === DataIntensity.High
          ? "pink"
          : intensity === DataIntensity.Low
          ? "gray"
          : // Intensity normal
            "blue"
      }.png`,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -20],
    });

  return (
    <Box>
      {/* Display chart title (full size map) */}
      {!isMinimap && (
        <Box display="flex" justifyContent="center" my={1}>
          <Typography variant="h3">{t("charts.map.title")}</Typography>
        </Box>
      )}
      <Box height={isMinimap ? minimapHeight : mapHeight}>
        <MapContainer
          center={mapDefaultCoordinates}
          zoom={isMinimap ? minimapDefaultZoom : mapDefaultZoom}
          zoomControl={isMinimap ? false : true}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* Map the markers from mapData */}
          {dataWithIntensity.map((dataPoint) =>
            dataPoint.location.latitude && dataPoint.location.longitude ? (
              <Marker
                key={dataPoint.location.id}
                icon={customIcon(dataPoint.intensity)}
                position={[
                  dataPoint.location.latitude,
                  dataPoint.location.longitude,
                ]}
              >
                <Popup className="leaflet-popup">
                  {dataPoint.location.label}: {dataPoint.count}
                </Popup>
              </Marker>
            ) : undefined
          )}
        </MapContainer>
      </Box>
    </Box>
  );
};

export default EvidenceMap;
