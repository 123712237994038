import { v4 as uuidv4 } from "uuid";

// Generate a UUID. Optional param to limit length
export function generateUniqueId(length?: number): string {
  if (!length) {
    return uuidv4();
  } else {
    const fullUuid = uuidv4();
    return fullUuid.slice(0, length);
  }
}
