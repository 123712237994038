// Globals
import { useEffect, useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

// MUI
import { Stack, Box, Link, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// Local
import createPresignedUrl from "../../lib/api/createPresignedUrl";
import { ApiTokenContext } from "../../contexts/InitContexts";
import uploadAttachments from "../../lib/api/uploadAttachments";
import { formatUniqueS3Filename } from "../../lib/util/formatters";
import { smallFont } from "../../lib/theme/default";

interface Props {
  attachment: File;
  uuid: string;
  attachmentIndex: number;
  isSubmitting: boolean;
  handleRemove: (index: number) => void;
  handleUploaded: (path: string) => void;
}

const AttachedFile = ({
  attachment,
  uuid,
  attachmentIndex,
  isSubmitting,
  handleRemove,
  handleUploaded,
}: Props) => {
  const [key, setKey] = useState<string>("");

  const token = useContext(ApiTokenContext);

  // If user clicks submit, get key for presignedUrl
  useEffect(() => {
    if (isSubmitting && !key) {
      // Get the filename extension
      const fileExtension = attachment.name.slice(
        attachment.name.lastIndexOf(".")
      );
      // Create an S3 filename
      const key = formatUniqueS3Filename({
        uuid,
        fileExtension,
        attachmentIndex,
      });
      setKey(key);
    }
  }, [isSubmitting]);

  // Get presignedUrl when key is created
  const {
    // isLoading,
    data: presignedData,
    error: presignedError,
  } = useQuery({
    queryKey: ["createPresignedUrl", token, key],
    queryFn: () => createPresignedUrl({ token, key }),
    enabled: !!key,
  });

  if (presignedError instanceof Error) {
    throw presignedError;
  }

  // This function uploads the file to S3 on isSubmitting
  // Informs parent component when completed (handleUploaded)
  const {
    data: uploadData,
    error: submitError,
  } = useQuery({
    queryKey: [
      "uploadAttachments",
      {
        presignedUrl: presignedData?.data.presignedUrl,
        currentFile: attachment,
      },
    ],
    queryFn: () =>
      uploadAttachments({
        presignedUrl: presignedData?.data.presignedUrl,
        attachment: attachment,
      }),
    enabled: !!presignedData?.data.presignedUrl,
  });

  if (submitError instanceof Error) {
    console.log("Error: ", submitError);
    throw Error;
  }

  // Update parent component that upload is complete
  useEffect(() => {
    if (uploadData?.status === 200) {
      console.log("Completed upload: ", presignedData?.data.key);
      handleUploaded(presignedData?.data.key);
    }
  }, [uploadData, presignedData]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      <Box>
        <UploadFileIcon sx={{ marginRight: 0.5 }} />
      </Box>
      <Box>
        <Typography fontSize={smallFont}>
          {attachment.name} [
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => handleRemove(attachmentIndex)}
            display="inline"
          >
            x
          </Link>
          ]
        </Typography>
      </Box>
    </Stack>
  );
};

export default AttachedFile;
