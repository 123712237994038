// Global
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // TimeSeriesScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

// Local
import { EvidenceAggregatedByDay } from "../../models/Evidence";
import { COLORS } from "../../lib/theme/default";
import {
  constructFullDateRange,
  getUtcDateLocalized,
} from "../../lib/util/dateFormatters";
import { lineChartHeight } from "../../lib/theme/default";
import { EvidenceFilters } from "../../models/EvidenceFilters";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  lineChartData: EvidenceAggregatedByDay[];
  evidenceFilters: EvidenceFilters;
}

// Display linechart within an AmpCard
const LineChart = ({ lineChartData, evidenceFilters }: Props) => {
  // TODO: Calc total days and adjust x axis labeling as appropriate

  const { t, i18n } = useTranslation();
  // TODO: Replace default language with hook from user preferences
  const userLang = i18n.language;

  // Display options for line chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {},
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("charts.lineChart.title"),
        color: COLORS.nearBlack,
        font: {
          family: "Manrope",
          weight: "500",
          size: 16,
        },
      },
    },
  };

  // If date range exceeds opensearch histogram range:
  // Extend charted days with count: 0
  const dataObj = constructFullDateRange({
    data: lineChartData,
    evidenceFilters,
  });

  // Map the object data over the time range
  const data = {
    // Get localized date format
    labels: dataObj.map((day) =>
      getUtcDateLocalized({
        date: day.date,
        userLang: userLang,
        isShortDate: true,
      })
    ),
    datasets: [
      {
        label: t("charts.lineChart.label"),
        data: dataObj.map((day) => day.count),
        borderColor: COLORS.primaryBlue,
        backgroundColor: COLORS.primaryBlue,
      },
    ],
  };

  // Return the line module with viz options and data
  return <Line options={options} data={data} height={lineChartHeight} />;
};
export default LineChart;
