export interface CountryDataProps {
  id: string;
  label: string;
  latitude?: number;
  longitude?: number;
  group?: string;
}

export const countryArray: CountryDataProps[] = [
  { id: "Africa", label: "Africa" },
  // { id: "AD", label: "Andorra" },
  // { id: "AE", label: "United Arab Emirates" },
  // { id: "AF", label: "Afghanistan" },
  // { id: "AG", label: "Antigua and Barbuda" },
  // { id: "AI", label: "Anguilla" },
  // { id: "AL", label: "Albania" },
  // { id: "AM", label: "Armenia" },
  { id: "AO", label: "Angola", latitude: -11.202692, longitude: 17.873887 },
  // { id: "AQ", label: "Antarctica" },
  // { id: "AR", label: "Argentina" },
  // { id: "AS", label: "American Samoa" },
  // { id: "AT", label: "Austria" },
  // { id: "AU", label: "Australia" },
  // { id: "AW", label: "Aruba" },
  // { id: "AX", label: "Alland Islands" },
  // { id: "AZ", label: "Azerbaijan" },
  // { id: "BA", label: "Bosnia and Herzegovina" },
  // { id: "BB", label: "Barbados" },
  // { id: "BD", label: "Bangladesh" },
  // { id: "BE", label: "Belgium" },
  {
    id: "BF",
    label: "Burkina Faso",
    latitude: 12.238333,
    longitude: -1.561593,
  },
  // { id: "BG", label: "Bulgaria" },
  // { id: "BH", label: "Bahrain" },
  { id: "BI", label: "Burundi", latitude: -3.373056, longitude: 29.918886 },
  { id: "BJ", label: "Benin", latitude: -3.373056, longitude: 29.918886 },
  // { id: "BL", label: "Saint Barthelemy" },
  // { id: "BM", label: "Bermuda" },
  // { id: "BN", label: "Brunei Darussalam" },
  // { id: "BO", label: "Bolivia" },
  // { id: "BR", label: "Brazil" },
  // { id: "BS", label: "Bahamas" },
  // { id: "BT", label: "Bhutan" },
  // { id: "BV", label: "Bouvet Island" },
  { id: "BW", label: "Botswana", latitude: -22.328474, longitude: 24.684866 },
  // { id: "BY", label: "Belarus" },
  // { id: "BZ", label: "Belize" },
  // { id: "CA", label: "Canada" },
  // { id: "CC", label: "Cocos (Keeling) Islands" },
  { id: "CD", label: "DRC", latitude: -4.038333, longitude: 21.758664 },
  { id: "CF", label: "CAR", latitude: 6.611111, longitude: 20.939444 },
  {
    id: "CG",
    label: "Congo, Republic of the",
    latitude: -0.228021,
    longitude: 15.827659,
  },
  // { id: "CH", label: "Switzerland" },
  { id: "CI", label: "Cote d'Ivoire", latitude: 7.539989, longitude: -5.54708 },
  // { id: "CK", label: "Cook Islands" },
  // { id: "CL", label: "Chile" },
  { id: "CM", label: "Cameroon", latitude: 7.369722, longitude: 12.354722 },
  // { id: "CN", label: "China" },
  // { id: "CO", label: "Colombia" },
  // { id: "CR", label: "Costa Rica" },
  // { id: "CU", label: "Cuba" },
  { id: "CV", label: "Cape Verde", latitude: 16.002082, longitude: -24.013197 },
  // { id: "CW", label: "Curacao" },
  // { id: "CX", label: "Christmas Island" },
  // { id: "CY", label: "Cyprus" },
  // { id: "CZ", label: "Czech Republic" },
  // { id: "DE", label: "Germany" },
  { id: "DJ", label: "Djibouti", latitude: 11.825138, longitude: 42.590275 },
  // { id: "DK", label: "Denmark" },
  // { id: "DM", label: "Dominica" },
  // { id: "DO", label: "Dominican Republic" },
  { id: "DZ", label: "Algeria", latitude: 28.033886, longitude: 1.659626 },
  // { id: "EC", label: "Ecuador" },
  // { id: "EE", label: "Estonia" },
  // { id: "EG", label: "Egypt" },
  // { id: "EH", label: "Western Sahara" },
  { id: "ER", label: "Eritrea", latitude: 15.179384, longitude: 39.782334 },
  // { id: "ES", label: "Spain" },
  { id: "ET", label: "Ethiopia", latitude: 9.145, longitude: 40.489673 },
  // { id: "FI", label: "Finland" },
  // { id: "FJ", label: "Fiji" },
  // { id: "FK", label: "Falkland Islands (Malvinas)" },
  // { id: "FM", label: "Micronesia, Federated States of" },
  // { id: "FO", label: "Faroe Islands" },
  // { id: "FR", label: "France" },
  { id: "GA", label: "Gabon", latitude: -0.803689, longitude: 11.609444 },
  // { id: "GB", label: "United Kingdom" },
  // { id: "GD", label: "Grenada" },
  // { id: "GE", label: "Georgia" },
  // { id: "GF", label: "French Guiana" },
  // { id: "GG", label: "Guernsey" },
  { id: "GH", label: "Ghana", latitude: 7.946527, longitude: -1.023194 },
  // { id: "GI", label: "Gibraltar" },
  // { id: "GL", label: "Greenland" },
  { id: "GM", label: "Gambia", latitude: 13.443182, longitude: -15.310139 },
  { id: "GN", label: "Guinea", latitude: 9.945587, longitude: -9.696645 },
  { id: "GP", label: "Guadeloupe", latitude: 16.995971, longitude: -62.067641 },
  {
    id: "GQ",
    label: "Equatorial Guinea",
    latitude: 1.650801,
    longitude: 10.267895,
  },
  // { id: "GR", label: "Greece" },
  // { id: "GS", label: "South Georgia and the South Sandwich Islands" },
  // { id: "GT", label: "Guatemala" },
  // { id: "GU", label: "Guam" },
  {
    id: "GW",
    label: "Guinea-Bissau",
    latitude: 11.803749,
    longitude: -15.180413,
  },
  // { id: "GY", label: "Guyana" },
  // { id: "HK", label: "Hong Kong" },
  // { id: "HM", label: "Heard Island and McDonald Islands" },
  // { id: "HN", label: "Honduras" },
  // { id: "HR", label: "Croatia" },
  // { id: "HT", label: "Haiti" },
  // { id: "HU", label: "Hungary" },
  // { id: "ID", label: "Indonesia" },
  // { id: "IE", label: "Ireland" },
  // { id: "IL", label: "Israel" },
  // { id: "IM", label: "Isle of Man" },
  // { id: "IN", label: "India" },
  // { id: "IO", label: "British Indian Ocean Territory" },
  // { id: "IQ", label: "Iraq" },
  // { id: "IR", label: "Iran, Islamic Republic of" },
  // { id: "IS", label: "Iceland" },
  // { id: "IT", label: "Italy" },
  // { id: "JE", label: "Jersey" },
  // { id: "JM", label: "Jamaica" },
  // { id: "JO", label: "Jordan" },
  // { id: "JP", label: "Japan" },
  { id: "KE", label: "Kenya", latitude: -0.023559, longitude: 37.906193 },
  // { id: "KG", label: "Kyrgyzstan" },
  // { id: "KH", label: "Cambodia" },
  // { id: "KI", label: "Kiribati" },
  { id: "KM", label: "Comoros", latitude: -11.875001, longitude: 43.872219 },
  // { id: "KN", label: "Saint Kitts and Nevis" },
  // { id: "KP", label: "Korea, Democratic People's Republic of" },
  // { id: "KR", label: "Korea, Republic of" },
  // { id: "KW", label: "Kuwait" },
  // { id: "KY", label: "Cayman Islands" },
  // { id: "KZ", label: "Kazakhstan" },
  // { id: "LA", label: "Lao People's Democratic Republic" },
  // { id: "LB", label: "Lebanon" },
  // { id: "LC", label: "Saint Lucia" },
  // { id: "LI", label: "Liechtenstein" },
  // { id: "LK", label: "Sri Lanka" },
  { id: "LR", label: "Liberia", latitude: 6.428055, longitude: -9.429499 },
  { id: "LS", label: "Lesotho", latitude: -29.609988, longitude: 28.233608 },
  // { id: "LT", label: "Lithuania" },
  // { id: "LU", label: "Luxembourg" },
  // { id: "LV", label: "Latvia" },
  // { id: "LY", label: "Libya" },
  // { id: "MA", label: "Morocco" },
  // { id: "MC", label: "Monaco" },
  // { id: "MD", label: "Moldova, Republic of" },
  // { id: "ME", label: "Montenegro" },
  // { id: "MF", label: "Saint Martin (French part)" },
  { id: "MG", label: "Madagascar", latitude: -18.766947, longitude: 46.869107 },
  // { id: "MH", label: "Marshall Islands" },
  // { id: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
  { id: "ML", label: "Mali", latitude: 17.570692, longitude: -3.996166 },
  // { id: "MM", label: "Myanmar" },
  // { id: "MN", label: "Mongolia" },
  // { id: "MO", label: "Macao" },
  // { id: "MP", label: "Northern Mariana Islands" },
  // { id: "MQ", label: "Martinique" },
  { id: "MR", label: "Mauritania", latitude: 21.00789, longitude: -10.940835 },
  // { id: "MS", label: "Montserrat" },
  // { id: "MT", label: "Malta" },
  { id: "MU", label: "Mauritius", latitude: -20.348404, longitude: 57.552152 },
  // { id: "MV", label: "Maldives" },
  { id: "MW", label: "Malawi", latitude: -13.254308, longitude: 34.301525 },
  // { id: "MX", label: "Mexico" },
  // { id: "MY", label: "Malaysia" },
  // { id: "MZ", label: "Mozambique" },
  { id: "NA", label: "Namibia", latitude: -22.95764, longitude: 18.49041 },
  // { id: "NC", label: "New Caledonia" },
  { id: "NE", label: "Niger", latitude: 17.607789, longitude: 8.081666 },
  // { id: "NF", label: "Norfolk Island" },
  { id: "NG", label: "Nigeria", latitude: 9.081999, longitude: 8.675277 },
  // { id: "NI", label: "Nicaragua" },
  // { id: "NL", label: "Netherlands" },
  // { id: "NO", label: "Norway" },
  // { id: "NP", label: "Nepal" },
  // { id: "NR", label: "Nauru" },
  // { id: "NU", label: "Niue" },
  // { id: "NZ", label: "New Zealand" },
  // { id: "OM", label: "Oman" },
  // { id: "PA", label: "Panama" },
  // { id: "PE", label: "Peru" },
  // { id: "PF", label: "French Polynesia" },
  // { id: "PG", label: "Papua New Guinea" },
  // { id: "PH", label: "Philippines" },
  // { id: "PK", label: "Pakistan" },
  // { id: "PL", label: "Poland" },
  // { id: "PM", label: "Saint Pierre and Miquelon" },
  // { id: "PN", label: "Pitcairn" },
  // { id: "PR", label: "Puerto Rico" },
  // { id: "PS", label: "Palestine, State of" },
  // { id: "PT", label: "Portugal" },
  // { id: "PW", label: "Palau" },
  // { id: "PY", label: "Paraguay" },
  // { id: "QA", label: "Qatar" },
  // { id: "RE", label: "Reunion" },
  // { id: "RO", label: "Romania" },
  // { id: "RS", label: "Serbia" },
  // { id: "RU", label: "Russian Federation" },
  { id: "RW", label: "Rwanda", latitude: -1.940278, longitude: 29.873888 },
  // { id: "SA", label: "Saudi Arabia" },
  // { id: "SB", label: "Solomon Islands" },
  { id: "SC", label: "Seychelles", latitude: -4.679574, longitude: 55.491977 },
  // { id: "SD", label: "Sudan" },
  // { id: "SE", label: "Sweden" },
  // { id: "SG", label: "Singapore" },
  // { id: "SH", label: "Saint Helena" },
  // { id: "SI", label: "Slovenia" },
  // { id: "SJ", label: "Svalbard and Jan Mayen" },
  // { id: "SK", label: "Slovakia" },
  {
    id: "SL",
    label: "Sierra Leone",
    latitude: 8.460555,
    longitude: -11.779889,
  },
  // { id: "SM", label: "San Marino" },
  { id: "SN", label: "Senegal", latitude: 14.497401, longitude: -14.452362 },
  // { id: "SO", label: "Somalia" },
  // { id: "SR", label: "Suriname" },
  {
    id: "SS",
    label: "South Sudan",
    latitude: 7.8626845,
    longitude: 29.6949232,
  },
  {
    id: "ST",
    label: "Sao Tome and Principe",
    latitude: 0.18636,
    longitude: 6.613081,
  },
  // { id: "SV", label: "El Salvador" },
  // { id: "SX", label: "Sint Maarten (Dutch part)" },
  // { id: "SY", label: "Syrian Arab Republic" },
  { id: "SZ", label: "Swaziland", latitude: -26.522503, longitude: 31.465866 },
  // { id: "TC", label: "Turks and Caicos Islands" },
  { id: "TD", label: "Chad", latitude: 15.454166, longitude: 18.732207 },
  // { id: "TF", label: "French Southern Territories" },
  { id: "TG", label: "Togo", latitude: 8.619543, longitude: 0.824782 },
  // { id: "TH", label: "Thailand" },
  // { id: "TJ", label: "Tajikistan" },
  // { id: "TK", label: "Tokelau" },
  // { id: "TL", label: "Timor-Leste" },
  // { id: "TM", label: "Turkmenistan" },
  // { id: "TN", label: "Tunisia" },
  // { id: "TO", label: "Tonga" },
  // { id: "TR", label: "Turkey" },
  // { id: "TT", label: "Trinidad and Tobago" },
  // { id: "TV", label: "Tuvalu" },
  // { id: "TW", label: "Taiwan, Republic of China" },
  {
    id: "TZ",
    label: "Tanzania",
    latitude: -6.369028,
    longitude: 34.888822,
  },
  // { id: "UA", label: "Ukraine" },
  { id: "UG", label: "Uganda", latitude: 1.373333, longitude: 32.290275 },
  // { id: "US", label: "United States" },
  // { id: "UY", label: "Uruguay" },
  // { id: "UZ", label: "Uzbekistan" },
  // { id: "VA", label: "Holy See (Vatican City State)" },
  // { id: "VC", label: "Saint Vincent and the Grenadines" },
  // { id: "VE", label: "Venezuela" },
  // { id: "VG", label: "British Virgin Islands" },
  // { id: "VI", label: "US Virgin Islands" },
  // { id: "VN", label: "Vietnam" },
  // { id: "VU", label: "Vanuatu" },
  // { id: "WF", label: "Wallis and Futuna" },
  // { id: "WS", label: "Samoa" },
  // { id: "XK", label: "Kosovo" },
  // { id: "YE", label: "Yemen" },
  // { id: "YT", label: "Mayotte" },
  {
    id: "ZA",
    label: "South Africa",
    latitude: -30.559482,
    longitude: 22.937506,
  },
  { id: "ZM", label: "Zambia", latitude: -13.133897, longitude: 27.849332 },
  { id: "ZW", label: "Zimbabwe", latitude: -19.015438, longitude: 29.154857 },
];
