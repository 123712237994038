// Local
import { countryArray } from "../../models/Countries";

export interface CountryListProps {
  flag: string | undefined; // Display flag element
  label: string; // Location full name
}

interface Props {
  countries: string[] | undefined;
}

const getCountryData = ({ countries }: Props) => {
  // TODO: flag if exists!

  // Build country list lookup
  const countryList = [] as CountryListProps[];

  // Return empty if no countries
  if (!countries) {
    return countryList;
  }

  const filteredCountries = countryArray.filter((obj) =>
    countries.includes(obj.id)
  );

  for (const c in filteredCountries) {
    countryList.push({
      flag: filteredCountries[c].id
        ? "fi fi-" + filteredCountries[c].id.toLowerCase()
        : undefined,
      label: filteredCountries[c].label,
    });
  }

  // Alphabetize list
  countryList.sort((a, b) => a.label.localeCompare(b.label));
  return countryList;
};

export default getCountryData;
