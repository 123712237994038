import { AddContentFormData } from "../../models/Content";

interface Props {
  formData: AddContentFormData;
  name?: string;
}

const cleanAddContent = ({ formData, name }: Props) => {
  // Assign missing static metadata
  formData.authorId = name;
  formData.binaryPath = formData.contentBinaryPath;
  formData.createdAt = new Date();
  formData.updatedAt = new Date();

  // User-chosen language determines mapping for
  // name and description
  // If user chooses Fr, use Fr. Otherwise, default to En
  formData.language = formData.contentLanguage === "French" ? "fr" : "en";
  formData.nameTranslated = { [formData.language]: formData.contentName };
  formData.descriptionTranslated = {
    [formData.language]: formData.contentDescription,
  };

  // Delete fields only used in UI
  delete formData.contentLanguage;
  delete formData.contentName;
  delete formData.contentDescription;
  delete formData.contentBinaryPath;

  // Check to make sure data is not missing
  if (
    formData.authorId === undefined ||
    (formData.nameTranslated?.fr === undefined &&
      formData.nameTranslated?.en === undefined) ||
    formData.binaryPath === undefined
  ) {
    throw new Error(
      "One or more properties are undefined: authorId, name, language, url"
    );
  }

  // Return cleaned data
  return formData;
};

export default cleanAddContent;
