// Global
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MUI
import { Box, Typography, Stack } from "@mui/material";

// Local
import AmpButton from "../buttons/AmpButton";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  header: string;
  subheader: string;
  buttonText: string;
}

// Controlled: Display view options on landing page
const ChooseView = ({ header, subheader, buttonText }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (buttonText === "Discover") {
      navigate(RouteOptions.discover);
    } else {
      navigate(RouteOptions.submit);
    }
  };

  return (
    <Box
      mt={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: "primary.main", borderRadius: 1 }}
    >
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign={"center"}
        p={2}
      >
        <Typography variant="h5">{t("chooseView." + header)}</Typography>
        <Typography variant="h4">{t("chooseView." + subheader)}</Typography>
        <Box marginBottom={2} marginTop={2}>
          <AmpButton type="accent" text={buttonText} onClick={handleClick} />
        </Box>
      </Stack>
    </Box>
  );
};

export default ChooseView;
