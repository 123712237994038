import { useEffect } from "react";

// Import formik and formik-mui
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AutocompleteMultiple from "./AutocompleteMultiple";

// Import MUI
import { Box, Stack } from "@mui/material";

// Import local
import { InputStepProps } from "../../lib/addEvidenceForms/props";
import TextFieldInput from "./TextFieldInput";
import AmpDatePicker from "./AmpDatePicker";
import { PlatformTypes } from "../../models/Platform";
import { urlSchema } from "../../lib/util/validators";
import { SocialMediaPostType } from "../../lib/util/enums";
import LShapedSpacer from "./LShapedSpacer";

const AddEvidenceStepTwo = ({
  handleAllowNext,
  updateFormData,
  formData,
}: InputStepProps) => {
  // Global hooks

  // handle Allow Next Button
  useEffect(() => {
    if (formData.text && formData.date && formData?.countries?.length) {
      handleAllowNext(true);
    } else handleAllowNext(false);
  }, [formData]);

  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>
      <Formik
        initialValues={{
          originalPost: formData.originalPost ? formData.originalPost : "",
          text: formData.text ? formData.text : "",
          mediaName: formData.mediaName ? formData.mediaName : "",
          url: formData.url ? formData.url : "",
        }}
        validationSchema={Yup.object({
          url: urlSchema,
        })}
        onSubmit={(values, { setSubmitting }) => {
          updateFormData(values);
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            {/* URL */}
            {(formData.platformObject?.type === PlatformTypes.SocialMedia ||
              formData.platformObject?.type === PlatformTypes.UserDefined) && (
              <TextFieldInput field="url" />
            )}

            {/* Original Post */}
            {(formData.socialMediaPostType === SocialMediaPostType.Comment ||
              formData.socialMediaPostType === SocialMediaPostType.Reply) && (
              <TextFieldInput field="originalPost" rows={3} rowsMobile={4} />
            )}

            {/* L-Shaped Spacer if Text is in response to Original Post */}
            <Stack direction="row" width={"100%"}>
              {(formData.socialMediaPostType === SocialMediaPostType.Comment ||
                formData.socialMediaPostType === SocialMediaPostType.Reply) && (
                <LShapedSpacer />
              )}

              {/* Primary Rumor Text */}
              <Box flexGrow={1}>
                <TextFieldInput
                  field="text"
                  rows={3}
                  rowsMobile={4}
                  required={true}
                />
              </Box>
            </Stack>

            <AmpDatePicker
              field="date"
              updateFormData={updateFormData}
              date={formData.date}
            />

            <AutocompleteMultiple
              field="countries"
              selectedValues={formData.countries}
              required={true}
              updateFormData={updateFormData}
            />

            {formData.platformObject?.type === PlatformTypes.Media && (
              <TextFieldInput field="mediaName" />
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddEvidenceStepTwo;
