// Global
import { useState } from "react";

// MUI
import { Box, useMediaQuery, useTheme } from "@mui/material";

// Local
import SubNav from "../components/navigation/SubNav";
import { RouteOptions } from "../lib/util/enums";
import { appMobileWidth, appMaxWidth } from "../lib/theme/default";
import AddContent from "../components/content/AddContent";
import ContentFilterControls from "../components/filters/ContentFilterControls";
import { contentSubviews } from "../lib/util/enums";
import ViewContentCards from "../components/content/ViewContentCards";

const ContentManager = () => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Local hooks and State
  const [subview, setSubview] = useState<contentSubviews>(
    contentSubviews.AddContent
  );

  return (
    <Box>
      {/* Subnav for Content manager */}
      <SubNav view={RouteOptions.content}>
        <ContentFilterControls subview={subview} setSubview={setSubview} />
      </SubNav>

      <Box display="flex" justifyContent={"center"}>
        <Box
          minWidth={isSmallScreen ? appMobileWidth : "auto"}
          maxWidth={isSmallScreen ? appMobileWidth : appMaxWidth}
          justifyContent={"center"}
        >
          {/* Display Add or View page */}
          {subview === "AddContent" && <AddContent />}
          {subview === "ViewContent" && <ViewContentCards />}
        </Box>
      </Box>
    </Box>
  );
};
export default ContentManager;
