import { useTranslation } from "react-i18next";

// MUI Imports
import { Card, Stack, Typography, Box, CardActionArea } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

// Local imports
import { Platform, PlatformTypes, Platforms } from "../../models/Platform";
import ChipInput from "./ChipInput";
import { SocialMediaPostType } from "../../lib/util/enums";

interface Props {
  platform: Platform;
  onClick: (platform: Platform) => void;
  handleSocialMediaClick: (values: any) => void;
}

// Build the complete platform card from array item provided
export default function PlatformCard({
  platform,
  onClick,
  handleSocialMediaClick,
}: Props) {
  const { t } = useTranslation();

  // If user clicks on social media, combine with platform and send to parent
  const handleSocialMediaType = (
    values: Record<string, string> | Record<string, string[]>
  ) => {
    const data = {
      ...values,
      platformObject: platform,
    };
    handleSocialMediaClick(data);
  };

  const cardContent = (
    <Stack justifyContent="center" alignItems={"center"} py={2}>
      {/* Insert the icon */}
      <Box height={40} width={40}>
        {platform.type === PlatformTypes.UserDefined ? (
          <QuestionMarkIcon fontSize="large" />
        ) : (
          <center>
            <img src={"/icons/" + platform.icon} alt={platform.name} />
          </center>
        )}
      </Box>
      {/* Display the text below the icon */}
      <Typography variant="h3" textAlign={"center"} mt={1}>
        {t("platforms." + platform.name)}
      </Typography>
      {platform.type === PlatformTypes.SocialMedia && (
        <Box mt={2}>
          <ChipInput
            label="socialMediaPostType"
            values={
              platform === Platforms.Twitter
                ? [SocialMediaPostType.Tweet, SocialMediaPostType.Reply]
                : platform === Platforms.YouTube ||
                  platform === Platforms.TikTok
                ? [SocialMediaPostType.Video, SocialMediaPostType.Comment]
                : [SocialMediaPostType.Post, SocialMediaPostType.Comment]
            }
            exclusive={true}
            updateFormData={handleSocialMediaType}
          />
        </Box>
      )}
    </Stack>
  );

  return (
    <Card
      onClick={() => onClick(platform)}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* Big click target for most cards */}
      {platform.type !== PlatformTypes.SocialMedia && (
        <CardActionArea>{cardContent}</CardActionArea>
      )}

      {/* Small click target for Social Media */}
      {platform.type === PlatformTypes.SocialMedia && <Box>{cardContent}</Box>}
    </Card>
  );
}
