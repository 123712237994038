// Global
import { useTranslation } from "react-i18next";

// MUI
import { Box, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// Local
import AmpDrawer from "../drawers/AmpDrawer";
import useAmpDrawer from "../../hooks/useAmpDrawer";
import { AmpDrawerState } from "../../lib/util/enums";
import FlagForReviewDrawer from "../drawers/FlagForReviewDrawer";
import { CardTypeAndId } from "../../lib/util/interfaces";



// This component allows users to notify admins of concerns
// with data or any other aspect of the app
const FlagForReview = ({ type, id }: CardTypeAndId) => {
  // Global hooks
  const { t } = useTranslation();

  // Local hooks and state
  const { drawerState, setDrawerState } = useAmpDrawer();

  return (
    <>
      {/* Flag For Review Icon */}
      <Box display='flex' onClick={() => setDrawerState(AmpDrawerState.Flag)}>
        <Tooltip title={t("cards.tooltip.FlagForReview")}>
          <ErrorOutlineIcon color="primary" />
        </Tooltip>
      </Box>

      {/* Flag For Review Drawer */}
      <AmpDrawer
        drawerState={drawerState}
        handleCloseDrawer={() => setDrawerState(AmpDrawerState.Closed)}
      >
        <FlagForReviewDrawer
          type={type}
          id={id}
          setDrawerState={setDrawerState}
        />
      </AmpDrawer>
    </>
  );
};
export default FlagForReview;
