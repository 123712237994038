import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Local
import Logout from "@mui/icons-material/Logout";
import { avatarSize } from "../../lib/theme/default";
import UserLanguage from "./UserLanguage";
import { RouteOptions } from "../../lib/util/enums";

interface Props {
  isMobile?: boolean;
}

// This function displays the menu to handle (future) user account settings and logout
export default function UserMenu({ isMobile }: Props) {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Menu anchor state management
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "user-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {!isMobile && (
            <Avatar sx={{ width: avatarSize, height: avatarSize }}>
              {user?.name?.slice(0, 1).toUpperCase()}
            </Avatar>
          )}
          {isMobile && <MenuIcon />}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* Menu Items */}
        {/* Toggle user language */}
        <MenuItem>
          <UserLanguage />
        </MenuItem>
        {/* Navigation options (mobile only) */}
        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.collection);
            }}
          >
            {t("viewName./collection")}
          </MenuItem>
        )}
        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.submit);
            }}
          >
            {t("viewName./submit")}
          </MenuItem>
        )}
        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.discover);
            }}
          >
            {t("viewName./discover")}
          </MenuItem>
        )}{" "}
        {isMobile && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(RouteOptions.analyze);
            }}
          >
            {t("viewName./analyze")}
          </MenuItem>
        )}
        {/* Access content manager */}
        {/* TODO: Restrict access to button, page */}
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(RouteOptions.content);
          }}
        >
          {t("userMenu.ContentManager")}
        </MenuItem>
        {/* Access QA Page */}
        {/* TODO: Restrict access to button, page */}
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(RouteOptions.QA);
          }}
        >
          {t("userMenu.QA")}
        </MenuItem>
        {/* Logout */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("userMenu.Logout")}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
